import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Buffer } from "buffer";
import { LoginService } from 'src/app/shared/http-services/login.service';

@Component({
  selector: 'app-re-password',
  templateUrl: './re-password.component.html',
  styleUrls: ['./re-password.component.css']
})

/**
 * Auth ReEnterPassword Component
 */
export class ReEnterPasswordComponent implements OnInit {
  form: FormGroup;
  year = new Date().getFullYear();
  email: string = '';
  coreId: any;
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
    this.formInitialization();
    setTimeout(() => {
      debugger
      let emiil = this.activatedRoute.snapshot.queryParamMap.get("el_m") as any;
      let license = this.activatedRoute.snapshot.queryParamMap.get("cor_se") as any;
      this.email = Buffer.from(emiil, 'base64').toString('binary');
      this.coreId = Buffer.from(license, 'base64').toString('binary');
      // this.verfyClient();
    }, 5000);
  }

  formInitialization(): void {
    this.form = this.fb.group({
      'customerPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  onSaveClicked(values: Object): void {
    if (this.form.invalid) {
      return;
    }
    // this.submit();
    this.router.navigate(['/login']);
  }

  submit(): void {
    this.loginService.recoverPassword(this.form.value).subscribe({
      next: x => {
        if (x.result != null) {
          this.router.navigate(['/login']);
        }
      }
    });
  }

}
