import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-client',
  templateUrl: './page-client.component.html',
  styleUrls: ['./page-client.component.css']
})
export class PageClientComponent implements OnInit {
  
  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = false;


  clientData = [
    {
      image: "assets/images/client/update.png",
      title: "Update Group",
      name: "Group of Company",
      date: "13th August, 2019",
      
    },
    {
      image: "assets/images/client/roadmaster.png",
      title: "Vehicle",
      name: "Vehicle Assembler",
      date: "13th August, 2019",
      
    },
    {
      image: "assets/images/client/mascom.png",
      title: "Group of Company",
      name: "Garments",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/trouserline-2.png",
      title: "Group of Company",
      name: "Garments",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/sp-garments-3.png",
      title: "Group of Company",
      name: "Gamrments",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/sp-washing-2.png",
      title: "Group of Company",
      name: "Garments",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/rpc.png",
      title: "Chemical Company",
      name: "Importer",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/time-power.png",
      title: "Group of Company",
      name: "Importer",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/ns1.jpg",
      title: "Construction Company",
      name: "Construction",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/grambangla.png",
      title: "NGO",
      name: "NGO",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/natab.png",
      title: "NGO",
      name: "NGO",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/aid-foundation.png",
      title: "NGO",
      name: "NGO",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/ied.png",
      title: "NGO",
      name: "NGO",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/reliance.jpg",
      title: "Courier",
      name: "Courier",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/flash.jpg",
      title: "Courier",
      name: "Courier",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/day2day.png",
      title: "eCommerce",
      name: "eCommerce",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/KBAC2.jpg",
      title: "Engineering",
      name: "Engineering",
      date: "13th August, 2019",
     
    },
    {
      image: "assets/images/client/roan2.jpg",
      title: "Engineering",
      name: "Engineering",
      date: "13th August, 2019",
     
    }
  ];


  constructor() { }

  ngOnInit(): void {
  }


}
