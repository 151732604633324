import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Buffer } from "buffer";
import { EmailService } from 'src/app/email/email.service';
import { emailValidator } from 'src/app/shared/http-services/app-validators';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/http-services/toast.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})

/**
 *  ReCover Repassword Component
 */
export class RecoverPasswordComponent implements OnInit {
  form: FormGroup;
  year = new Date().getFullYear();
  public message: any = "";
  constructor(private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService,
    private toastService: ToasterService, private loginService: LoginService, private mail: EmailService) { }

  ngOnInit(): void {
    this.formInitialization();
  }
  formInitialization(): void {
    this.form = this.fb.group({
      'email': [null, Validators.compose([Validators.required, emailValidator])],
    });
  }

  onSaveClicked(values: Object): void {
    if (this.form.invalid) {
      return;
    }
    this.submit();
  }

  submit(): void {
    this.spinner.show();
    this.loginService.checkUserEmail(this.form.value).subscribe({
      next: x => {
        if (x.result != null) {
          this.sendRecoveryEmail(x.result);
        } else {
          this.spinner.hide();
          this.message = "Email did not match";
        }
      },
      complete: () => console.log(''),
      error: err => console.error('An error occurred', err),
    });
  }

  sendRecoveryEmail(data: any): void {
    
    let emailf = Buffer.from(data.customerEmail).toString('base64');
    let coref = Buffer.from(data.coreEssentialSerial.toString()).toString('base64');

    let mailContent = this.mail.receverPassword(data.customerName, emailf, coref);
    let obj = {
      receiverEmail: data.customerEmail,
      subject: 'Recover Password',
      mailContent: mailContent
    };

    this.loginService.sendMail(obj).subscribe(res => {
      if (res.result) {
        this.spinner.hide();
        this.toastService.success("A verification link send your mail address please check it", "");
        this.router.navigate(['/page-actions']);
      } else {
        this.toastService.warning("Can't send email..Please contact with administrator", "");
        this.spinner.hide();
      }
    });
  }
}
