<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 "
  style="background-image: url('assets/images/charja/solutions-top-background-line-art.png');" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h1 class="title mb-0" style="color: #fff;text-transform: uppercase;"> Cheque Issue Register </h1>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <!-- <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
          </nav>
      </div> -->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center" style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
      <div class="section-title ms-lg-4">
        <!-- <h4 class="title mb-4 ja-style">Who Can Apply ?</h4> -->
        <p class="text-muted" style="text-align: justify;">Normally there are huge bank/cheque transactions in a large
          company or a group of companies.It becomes the management headache to track the cheque issued or to be issued.
          This module can help you to track all bank account’s all cheque issued or to be issued as well as keeping
          regular check register. Before signing any check the Signatory can check whether it is approved or not.</p>
      </div>
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <ul class="list-unstyled text-muted">
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Cheque Register Management</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Cheque Book History</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Cheque Issue
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Cheque Issue Permission</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Auto Cheque and Acknowledgement Slip Print</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Auto Voucher Generation
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Integrated with Main Ledger</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
            <img width="18px" src="assets/images/charja/solutions-tick.png">
              </span>Reports</li>
        </ul>
      </div>


      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <div class="position-relative">
            <img src="assets/images/charja/cheque-issue-register.png" class="rounded img-fluid mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <div style="text-align: center;">
        <a routerLink="/signup" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a>
      </div>
    </div>
    
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Key Features</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>

    <app-features></app-features>
  </div> -->

</section>


<!-- Team Start -->
<!-- class="bg-light" -->
<!-- <section class="section ">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 ja-style">Our Greatest Minds</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>


    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
              <ul class="list-unstyled mb-0 team-icon">
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-primary btn-pills btn-sm btn-icon ms-1" *ngFor="let item of data.list">
                    <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
              <small class="designation text-muted">{{data.designation}}</small>
            </div>
          </div>
        </div>
      </div>
     
    </div>


    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title mb-4">See everything about your employee at one place.</h4>
            <p class="text-muted para-desc mx-auto">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>

            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Get Started Now</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a>
            </div>
          </div>
        </div>
      
      </div>
  
    </div>
  </div>
</section> -->

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>