<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0 ja-style">Charja | Get Started</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row ">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card-c custom-form rounded border-0 shadow p-4" style="margin-bottom:10px;">
          <form [formGroup]="form" autocomplete="off">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Company Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input formControlName="concernName" name="name" id="name" type="text" class="form-control ps-5"
                      placeholder="">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Domain <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input formControlName="customerDomain" name="email" id="email" type="text"
                      class="form-control ps-5" placeholder="">
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Your Country <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <!-- <i-feather name="mail" class="fea icon-sm icons"></i-feather> -->
                    <select formControlName="countrySerial" name="option" class="form-control form-select"
                      id="selectAction" (change)="functionname($event.target.value)">
                      <option *ngFor="let item of countries" [value]="item.id">
                        {{item.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">No. Of Users<span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input formControlName="concernSize" name="email" id="email" type="text" class="form-control ps-5"
                      placeholder="">
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Prymary Inerest <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <!-- <i-feather name="mail" class="fea icon-sm icons"></i-feather> -->
                    <select formControlName="customerPurpose" name="option" class="form-select form-control"
                      (change)="functionname($event.target.value)">
                      <option *ngFor="let item of prymayInterests" [value]="item.id">
                        {{item.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
             
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid p-3">
                  <button type="submit" [disabled]="!(form.valid)" (click)="creat_trial()" id="submit" name="send"
                    class="btn btn-primary">Create</button>

                    <!-- <button type="submit"  (click)="send_test_mail('Jubayer','jubayear.iu0708@gmail.com','01993534813')" id="submit" name="send"
                    class="btn btn-primary">Send Message</button> -->
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->
      <!-- <ngx-spinner type="ball-newton-cradle"></ngx-spinner> -->
      <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Please wait...</p>
      </ngx-spinner>
      
      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4" style="line-height: 0px !important;">
          <div class="rounded shadow p-4 card-d">
            <!-- <div class="d-flex justify-content-between p-4 bg-light">
              <h6 class="mb-0">Author</h6>
              <h6 class="mb-0">Date</h6>
            </div> -->

            <div class="p-4" *ngFor="let data of formData">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <a class="pe-3" href="javascript:void(0)">
                    <img src="{{data.profile}}" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                  </a>
                  <div class="flex-1 commentor-detail">
                    <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading" style="color: #4b6cda;">{{data.name}}</a></h6>
                    <!-- <small class="text-muted">Author</small> -->
                  </div>
                </div>
                <!-- <small class="text-muted">{{data.date}} <br> at {{data.time}}</small> -->
              </div>
              <div class="mt-3">
                <p class="text-muted mb-0">{{data.content}}</p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->