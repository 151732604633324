import { Component, Injectable } from '@angular/core';
import { ApiHttpService } from './api-http.service';
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
//import { ApiHttpService } from "../../shared/services/api-http.service";



@Injectable({
    providedIn: 'root',
})

export class LoginService {
    url: string = '';
    private loggedIn = false;
    private _token: string;
    public isLoginStatus: boolean = false;
    public solutions: any = [];

    constructor(private apiHttpService: ApiHttpService, private router: Router,) {

    }


    loginOne(data:any){
        this.url = 'demo/auths/login-one';
        return this.apiHttpService.POST(this.url, data);
    }

    checkUserEmail(data:any){
        this.url = 'demo/auths/check-email';
        return this.apiHttpService.POST(this.url, data);
    }

    recoverPassword(data:any){
        this.url = 'demo/auths/recover-password';
        return this.apiHttpService.POST(this.url, data);
    }

    register(data: any) {
        this.url = 'demo/record/register-now';
        return this.apiHttpService.POST(this.url, data);
    }

    updateRegister(data: any) {
        this.url = 'demo/record/update-now';
        return this.apiHttpService.POST(this.url, data);
    }
    exeDMLSesame(data: any) {
        this.url = 'demo/record/exe-dml-sesame';
        return this.apiHttpService.POST(this.url, data);
    }

    exeDMLBasicExtra(data: any) {
        this.url = 'demo/record/exe-dml-basics';
        return this.apiHttpService.POST(this.url, data);
    }

    getDemoFeatures() {
        this.url = 'demo/record/get-demo-features/';
        return this.apiHttpService.GET(this.url);
    }

    sendMail(data: any) {
        this.url = 'demo/record/send-mail';
        return this.apiHttpService.POST(this.url, data);
    }

    verfyClient(email: any, license: any) {
        this.url = 'demo/record/verify-client/' + email + '/' + license;
        return this.apiHttpService.GET(this.url);
    }

    demoDetails(id: any) {
        this.url = 'demo/record/get-demo-details/' + id;
        return this.apiHttpService.GET(this.url);
    }

    isLoggedIn() {
        if (this.getToken()) {
            return true;
        }
        else {
            return false;
        }
    }

    public setToken(res: any) {
        this.loggedIn = true;
        localStorage.setItem('minor', res['tokenString']);
        localStorage.setItem('userName', res['userName']);
        // localStorage.setItem('email', res['email']);
        // localStorage.setItem('concernSerial', res['concernSerial']);
        // localStorage.setItem('handlerType', res['handlerGenre']);
        // localStorage.setItem('gtroup', res['groupName']);
        this._token = res['tokenString'];

    }
    public setEmail(res: any) {
        localStorage.setItem('erpemail', res.email);
    }

    public getToken() {
        if (typeof window !== 'undefined') {
            const token: string = <string>localStorage.getItem('minor');
            this._token = token;
            return token;
        }
        return null;
    }



    public getUserName() {
        if (typeof window !== 'undefined') {
            const user: string = <string>localStorage.getItem('erpuserName');
            return user;
        }
        return "";
    }

    setConpmany(): void {

    }




    public clearLocalStorageData(): void {
        localStorage.removeItem('minor');
        localStorage.removeItem('erpuserName');
        localStorage.removeItem('erpemail');
        localStorage.removeItem('erpconcernSerial');
        localStorage.removeItem('erpuserSerial');
        localStorage.removeItem('erpconcern');
        localStorage.removeItem('solutions');
    }

    loginStatus(): void {

        if (this.isLoggedIn()) {
            let token = localStorage.getItem('minor');
            const date = this.getTokenExpirationDate(token);
            if (date === undefined) {
                this.router.navigate(['/phone-number']);
                this.clearLocalStorageData();
                this.isLoginStatus = false;
            } else {
                let condition = (date.valueOf() > new Date().valueOf());
                if (condition == true) {

                    this.isLoginStatus = true;
                } else {
                    this.router.navigate(['/phone-number']);
                    this.clearLocalStorageData();
                    this.isLoginStatus = false;
                }
            }
        } else {
            this.router.navigate(['/phone-number']);
            this.isLoginStatus = false;
        }

    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwtDecode(token);
        if (decoded.exp === undefined) return null;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    async logOut() {
        localStorage.removeItem('minor');
        localStorage.removeItem('userName');
        localStorage.removeItem('email');
        localStorage.removeItem('concernSerial');
        localStorage.removeItem('handlerType');
        localStorage.removeItem('gtroup');
    }

}