import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'Rxjs/Observable';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { Observable } from 'rxjs';

//import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';


@Injectable({
    providedIn: 'root',
})
export class ApiHttpService {
    private handleError: HandleError;
    baseUrl: string = '';
    realTimeUrl: string = '';

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler
    ) {

        // this.baseUrl = "https://localhost:44303/api/";
 
         this.baseUrl = "https://charja.software/API/CHARJAWEBSITEAPI/api/";

        //Signal
        //  this.realTimeUrl = "https://localhost:44350/location";
        // this.realTimeUrl = "http://charja.software/API/TLINETESTAPI/location";


        this.handleError = httpErrorHandler.createHandleError('ApiHttpService');

    }
    public GETDATATABLE(url: string, dtParams: any): Observable<any> {
        let dtUrl = this.baseUrl + url;
        return this.http.post<any>(
            dtUrl, dtParams,
            {}
        );
    }

    public GETMATTABLE(url: string, params: any): Observable<any> {
        let uri = this.baseUrl + url;
        return this.http.post<any>(uri, params, {});
    }

   

    RouterGet(url: string): Observable<any> {
        return this.http.get(this.baseUrl + url, { responseType: 'json' });
    }

    GET(url: string): Observable<any> {
        return this.http.get(this.baseUrl + url, { responseType: 'json' });
    }

    POST(url: string, data: any): Observable<any> {
        return this.http.post(this.baseUrl + url, data, { responseType: 'json' });
    }

    PUT(url: string, data: any): Observable<any> {
        return this.http.put(this.baseUrl + url, data, { responseType: 'json' });
    }

    DELETE(url: string): Observable<any> {
        return this.http.delete(this.baseUrl + url, { responseType: 'json' });
    }

    POSTTODELETE(url: string, data: any[]): Observable<any> {
        return this.http.post(this.baseUrl + url, data, { responseType: 'json' });
    }

    GETRemoteValidation(url: string): Observable<any> {
        return this.http.get(this.baseUrl + url, { responseType: 'json' });
    }

    //GETASPX(url: string): Observable<any> {
    //    return this.http.get(url, { responseType: 'json' });
    //}



    GETASPX(url: string): Observable<any> {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }

        const requestOptions = {
            headers: new Headers(headerDict)
        };

        let tokenValue = "Bearer " + localStorage.getItem('minor');
        /* let headers = new HttpHeaders({"Authorization": tokenValue, 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json', 'Access-Control-Allow-Headers': 'Content-Type' });*/
        let headers = new HttpHeaders({
            /*  "Authorization": tokenValue,*/
            "access-control-allow-origin": "*",
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Methods': '*',
            'Content-Type': 'document',

        });

        return this.http.get(url, { headers: headers });
    }

}
