<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 "
  style="background-image: url('assets/images/charja/solutions-top-background-line-art.png');" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h1 class="title mb-0" style="color: #fff;text-transform: uppercase;">{{btnStatus ? 'Courier':'কুরিয়ার'}}  </h1>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <!-- <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
          </nav>
      </div> -->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div>
        <div class="form-check form-switch " style="float: right;">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            (change)="setIsCheckd($event.target.checked)">
          <label class="form-check-label" for="flexSwitchCheckDefault" style="cursor: pointer;">{{btnStatus?'বাংলা':'English'}}</label>
        </div>
      </div>

      <div class="section-title ms-lg-4">
        <h4 class="title mb-4 ja-style">{{headerText}}</h4>
      </div>
      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/charja/courier.png" class="rounded img-fluid mx-auto d-block" alt="">

        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <p class="text-muted" style="text-align: justify;">{{featureHeadText}}</p>
        </div>
      </div>
      <div style="text-align: center;">
        <a routerLink="/signup" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a>
      </div>
      <!--end col-->
    </div>

    <!--end row-->
  </div>

  <!--end container-->

  <div class="container mt-100 mt-60">

    <hr class="hr hr-blurry" />
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 ja-style">{{featureTitle}}</h4>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-4 col-12 mt-3" *ngFor="let data of servicesData">
        <div class="{{service_class}}">
          <div class="image position-relative d-inline-block">
            <img width="75px" src="{{data.icon}}" class="img-fluid" alt="">
          </div>
          <div class="content mt-3">
            <h5 style="font-size: 1.2rem !important;font-weight: bold;color: #2f55d4;">{{data.title}}</h5>
            <p class="text-muted mb-0" style="text-align: justify !important;">{{data.description}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <hr class="hr hr-blurry" />
  <div *ngIf="btnStatus" class="container mt-100 mt-60"
    style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 ja-style">Other advantages or Features</h4>
        </div>
      </div>

    </div>
    <!-- <div class="row align-items-end">
      <ul class="list-unstyled text-muted dropdown-content" >
        <li class="mb-0" *ngFor="let item of advanceFeatureEng"><span class="text-primary h5 me-2"><i
              class="uil uil-check-circle align-middle"></i></span>{{item.title}}</li>
      </ul>
    </div> -->
    <div class="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
      <div class="section-title ms-lg-4" style="align-items: center;">
        <ul class="list-unstyled text-muted">
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
              <!-- <i class="uil uil-check-circle align-middle"></i> -->
            </span> <span class="text-primary fw-bold">Real Time Order Request :</span> To collect parcels the merchant
            can send order requests from his own panel. </li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>The Courier Authority can instruct the Pickup Person to collect the parcel from the merchant by
            sending a Pickup Request</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span> <span class="text-primary fw-bold">Real Time Order Notification :</span> The Courier Authority will
            get an automated and real time order notification from the merchants on
          </li>
          <li class="mb-0" style="margin-left:17px"><span class="text-primary h5 me-2">
            </span>
            their own panel.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span> User Friendly Order Management System.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Rider Management</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span> Mobile App for Merchant and Rider
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>SMS and Email alert. Charge applicable for SMS.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Advanced feature of receiving bulk order at a time from CVS and Excel.</li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Online Support.</li>

        </ul>

      </div>

    </div>

  </div>
  <div *ngIf="!btnStatus" class="container mt-100 mt-60"
    style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 ja-style"> অন্যান্য সুবিধা বা বৈশিষ্ট্য</h4>

        </div>
      </div>

    </div>

    <div class="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
      <div class="section-title ms-lg-4" style="align-items: center;">
        <ul class="list-unstyled text-muted">
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
              <!-- <i class="uil uil-check-circle align-middle"></i> -->
            </span> <span class="text-primary fw-bold">
              রিয়েল টাইম অর্ডার অনুরোধ :</span> পার্সেল সংগ্রহ করতে ব্যবসায়ী তার নিজস্ব প্যানেল থেকে অর্ডার অনুরোধ
            পাঠাতে পারেন । </li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>কুরিয়ার কর্তৃপক্ষ পিকআপ ব্যক্তিকে একটি পিকআপ অনুরোধ পাঠিয়ে ব্যবসায়ীর কাছ থেকে পার্সেল সংগ্রহ করার
            নির্দেশ দিতে পারে ।</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span> <span class="text-primary fw-bold">
              রিয়েল টাইম অর্ডার বিজ্ঞপ্তি :</span>কুরিয়ার কর্তৃপক্ষ তাদের নিজস্ব প্যানেলে ব্যবসায়ীদের কাছ থেকে একটি
            স্বয়ংক্রিয় এবং রিয়েল টাইম অর্ডার ।
          </li>
          <li class="mb-0" style="margin-left:17px"><span class="text-primary h5 me-2">
            </span>
            বিজ্ঞপ্তি পাবে ।</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>ব্যবহারকারী বান্ধব অর্ডার ম্যানেজমেন্ট সিস্টেম ।</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>রাইডার ম্যানেজমেন্ট ।</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>বণিক এবং রাইডারের জন্য মোবাইল অ্যাপ ।
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>
            এসএমএস এবং ইমেল সতর্কতা। SMS এর জন্য চার্জ প্রযোজ্য ।</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>CVS এবং Excel থেকে এক সময়ে বাল্ক অর্ডার পাওয়ার উন্নত বৈশিষ্ট্য ।</li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>অনলাইন সাপোর্ট ।</li>

        </ul>

      </div>

    </div>

  </div>
  <hr class="hr hr-blurry" />
  <div *ngIf="!btnStatus" class="container mt-100 mt-60"
    style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4 ja-style">আসুন এখানে কিছু আসল দাম করা যাক।</h4>
        </div>
      </div>

    </div>
    <div class="row align-items-end">

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow" style="background: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">প্রিমিয়াম</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">মাসিক চার্জ</h6>
            <h2 class="fw-bold" style="color: #13397c;">৩,০০০</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                ৫০০
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color:rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">ব্রোঞ্জ</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">মাসিক চার্জ</h6>
            <h2 class="fw-bold" style="color: #13397c;">৫,০০০</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                ১৫০০
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">সিলভার</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">মাসিক চার্জ</h6>
            <h2 class="fw-bold" style="color: #13397c;">৯,০০০</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                ৩০০০
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">গোল্ড</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">মাসিক চার্জ</h6>
            <h2 class="fw-bold" style="color: #13397c;">১৫,০০০</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                ৮০০০
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">প্লাটিনাম</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">মাসিক চার্জ</h6>
            <h2 class="fw-bold" style="color: #13397c;">২২,০০০</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                ২০০০০
              </h6>

            </ul>
          </div>
        </div>

      </div>

    </div>

  </div>
  <div *ngIf="btnStatus" class="container mt-100 mt-60"
    style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <!-- <h4 class="title mb-4 ja-style"> do some real pricing here</h4> -->
          <h4 class="title mb-4 "> <span style="color: #13397c;">LET's</span> <span class="ja-style"> do some real pricing here</span> </h4>
        </div>
      </div>

    </div>
    <div class="row align-items-end">

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow" style="background: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">Premium</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">
              Monthly charges</h6>
            <h2 class="fw-bold" style="color: #13397c;">3,000</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">
              Monthly order</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                500
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color:rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">Bronze</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">Monthly charges</h6>
            <h2 class="fw-bold" style="color: #13397c;">5,000</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">Monthly order</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
               1500
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">Silver</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">Monthly charges</h6>
            <h2 class="fw-bold" style="color: #13397c;">9,000</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">Monthly order</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                3000
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">Gold</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">Monthly charges</h6>
            <h2 class="fw-bold" style="color: #13397c;">15,000</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">Monthly order</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                8000
              </h6>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow"
          style="background-color: rgb(189 209 255 / 40%);">
          <div class="price-header border-bottom pt-5 pb-5" style="background-color: #13397c;">
            <h5 class="price-title" style="color: #fff;">Platinum</h5>
          </div>
          <div class=" py-4">
            <h6 class="mb-0 fw-normal" style="color:#2f55d4">Monthly charges</h6>
            <h2 class="fw-bold" style="color: #13397c;">22,000</h2>
          </div>
          <div class="pricing-features  p-4">
            <h5 style="color: #13397c;">Monthly order</h5>
            <ul class="feature list-unstyled mb-0">
              <h6 style="color: #13397c;">
                20000
              </h6>

            </ul>
          </div>
        </div>

      </div>

    </div>

  </div>

</section>



<!-- Team Start -->
<!-- class="bg-light" -->
<!-- <section class="section ">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 ja-style">Our Greatest Minds</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>


    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
              <ul class="list-unstyled mb-0 team-icon">
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-primary btn-pills btn-sm btn-icon ms-1" *ngFor="let item of data.list">
                    <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
              <small class="designation text-muted">{{data.designation}}</small>
            </div>
          </div>
        </div>
      </div>
     
    </div>


    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title mb-4">See everything about your employee at one place.</h4>
            <p class="text-muted para-desc mx-auto">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>

            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Get Started Now</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a>
            </div>
          </div>
        </div>
      
      </div>
  
    </div>

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
  </div>
</section> -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
[ngxScrollTo]="'#home'">
<i-feather name="arrow-up" class="icons"></i-feather>
</a>