<!-- Hero Start -->
<!-- <section class="bg-half-170 bg-light d-table w-100" id="home"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0">Contact Us</h4>
        </div>
      </div>
    </div>

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Contact</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact Three</li>
        </ul>
      </nav>
    </div>
  </div> 
</section> -->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- Start Contact -->
<section class="section"
  style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6  mt-4 pt-2">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title" style="text-transform: uppercase;color: #13397c;">Get In Touch !</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Subject</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="subject" id="subject" class="form-control ps-5" placeholder="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Comments <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-4">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-6 ps-md-3 pr-md-3 mt-4 pt-2">
        <div class="card map map-height-two rounded map-gray border-0">
          <div class="card-body p-0">

            <iframe src="https://www.google.com/maps/embed/v1/directions?&origin=Batasignal+bustop+Bangladesh
            &destination=P9RQ+7MV Journalist's Den, Dhaka 1205,Bangladesh
            &avoid=tolls|highways&key=AIzaSyCa7wmJUt3QkavMZ8tBz_M_Qh-x1XuJLIk" style="border:0" class="rounded"
            allowfullscreen=""></iframe>

            <!-- <iframe src="https://www.google.com/maps/embed/v1/directions?&origin=Batasignal+bustop+Bangladesh
              &destination=Charja+Solutions+Ltd
              &avoid=tolls|highways&key=AIzaSyCa7wmJUt3QkavMZ8tBz_M_Qh-x1XuJLIk" style="border:0" class="rounded"
              allowfullscreen=""></iframe> -->
        
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-md-4">
        <div class=" border-0 text-center features feature-primary ">
          <div class="icons text-center mx-auto">
            <!-- <i class="uil uil-phone d-block rounded h3 mb-0"></i> -->

          </div>
          <div class="content mt-4 col">
            <h5 class="fw-bold ja-style">Phone</h5>
            <p class="text-muted">Come and visit our quarters or simply call us an anytime you want. We are open to all
              suggestions from our audience.</p>
            <div class="icons text-center mx-auto">
              <img width="30px" src="assets/images/charja/contacts-icons-phone.png">
            </div>

            <a href="tel:+152534-468-854" class="text-primary">09643226363</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class=" border-0 text-center features feature-primary ">
          <div class="icons text-center mx-auto">
            <!-- <i class="uil uil-envelope d-block rounded h3 mb-0"></i> -->

          </div>
          <div class="content mt-4">
            <h5 class="fw-bold ja-style">Email</h5>
            <p class="text-muted">Come and visit our quarters or simply send us an email anytime you want. We are open
              to all suggestions from our audience.</p>
            <div class="icons text-center mx-auto">
              <img width="30px" src="assets/images/charja/contacts-icons-mail.png">
            </div>

            <a href="mailto:contact@example.com" class="text-primary">support@charja.solutions</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class=" border-0 text-center features feature-primary ">
          <div class="icons text-center mx-auto">
            <!-- <i class="uil uil-map-marker d-block rounded h3 mb-0"></i> -->

          </div>
          <div class="content mt-4">
            <h5 class="fw-bold ja-style">Location</h5>
            <p class="text-muted">129-Kha/1, Elephant Road,
              New Market, Dhaka-1205</p>
            <div class="icons text-center mx-auto mt-5">
              <img width="30px" src="assets/images/charja/contacts-icons-address.png">
            </div>

            <a (click)="mapView(content)" href="javascript:void(0);" class="video-play-icon text-primary">View on Google
              map</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://www.google.com/maps/embed/v1/directions?&origin=Batasignal+bustop+Bangladesh
                  &destination=Charja+Solutions+Ltd
                  &avoid=tolls|highways&key=AIzaSyCa7wmJUt3QkavMZ8tBz_M_Qh-x1XuJLIk" height="450" width="750">
                </iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->