import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-service',
  templateUrl: './page-service.component.html',
  styleUrls: ['./page-service.component.css']
})

/**
 * page Blog-Detail-Two Component
 */
export class PageServiComponent implements OnInit {

      // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false;
  /**
   * Nav Light Class Add
   */
  navClass = 'nav-light';

  constructor() { }

  ngOnInit(): void {
  }


}
