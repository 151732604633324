<!-- Hero Start -->
<!-- bg-half -->
<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/charja/bg_1.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="juc-image">
        <img width="50%" src="assets/images/charja/clientele.png" class="img-fluid" alt="">
      </div> -->
       <div class="img-center">
        <img  src="assets/images/charja/clientele.png" class="img-fluid image-size" alt="">
      </div>

      <!-- <div class="col-lg-7 col-md-6">
        <img src="assets/images/charja/clientele.png" class="img-fluid" alt="">
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
     
      </div> -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!-- Hero End -->

<!-- Start Works -->
<section class="section">
  <div class="container">
    <div class="row projects-wrapper"
      style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
      <div class="section-title text-center mb-4 pb-2">
        <h1 class="title mb-4 ja-style">Our Happy Client</h1>
      </div>
      <div class="col-lg-2 col-md-6 col-12 mb-4 pb-2" *ngFor="let item of clientData">
        <div class=" work-container work-modern position-relative overflow-hidden"
          style="border:1px solid #13397c !important">
          <div class="card-body p-0">
            <img src="{{item.image}}" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <!-- routerLink="/page-work-detail" -->
              <a class="title text-white d-block font-weight-bold">{{item.title}}</a>
              <small class="text-light">{{item.name}}</small>
            </div>
            <div class="client">
              <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small> -->
              <small class="text-light date"><i class="mdi mdi-calendar-check"></i> {{item.date}}</small>
            </div>
          </div>
        </div>
      </div>


      <!-- PAGINATION START -->
      <!-- <div class="col-12">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
          <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
          <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
        </ul>
      </div> -->
      <!--end col-->
      <!-- PAGINATION END -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Works -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->