<!-- Hero Start -->
<!-- assets/images/marketing/marketing.png -->

<section class="bg-half-170 bg-light d-table w-100"
  style="background-image: url('assets/images/charja/welcome-3.png');margin-top: 75px " id="home">
  <div class="container">
    <!-- <div class="row mt-5 justify-content-center">
          <div class="col-lg-12 text-center">
              <div class="pages-heading">
                  <h3 class="fw-bold mb-0"> Welcome to Charja ERP </h3>
              </div>
          </div>
      </div> -->

    <!-- <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item active" aria-current="page">Forums</li>
              </ul>
          </nav>
      </div> -->
  </div>
</section>
<!-- 
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->



<section class="section">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h3 class="fw-bold mb-0 label-style"> Welcome to Charja Solutions </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- <div class="text-center subcribe-form mb-2">
          <form style="max-width:800px;">
            <input type="text" id="name" name="name" class="rounded-pill shadow" placeholder="Search forums..."
              required>
            <button type="submit" class="btn btn-pills btn-primary">Search Forums</button>
          </form>
         
        </div> -->

        <div class="table-responsive bg-white shadow rounded mt-4">
          <table class="table mb-0 table-center table-primary">
            <thead class="bg-light">
              <tr>
                <th scope="col" class="border-bottom p-3 text-center" style="min-width: 150px;">Domain</th>
                <th scope="col" class="border-bottom p-3 text-center" style="max-width: 150px;">Compnay</th>
                <th scope="col" class="border-bottom p-3 text-center" style="width: 150px;">Company Size</th>
                <th scope="col" class="border-bottom p-3 text-center" style="max-width: 150px;">Solution</th>
                <th scope="col" class="border-bottom p-3 text-center" style="max-width: 150px;">Start</th>
                <th scope="col" class="border-bottom p-3 text-center" style="width: 150px;">End</th>
                <th scope="col" class="border-bottom p-3 text-center" style="width: 150px;">Connect</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of dataSource">
                <td class="p-3">
                  <div class="d-flex dropdown">
                    <ng-template #htmlContent>
                      <table>
                        <tr *ngFor="let solution of data.solutionsName;let i=index">
                          <td style="text-align: left;">{{i+1}}. {{ solution.name }}</td>
                        </tr>
                      </table>
                    </ng-template>
                    <i class="uil uil-comment text-muted h5 dropbtn" placement="right" [ngbTooltip]="htmlContent"
                      tooltipClass="custom-tooltip"></i>
                    <div class="flex-1 content ms-3">
                      <p class="text-muted small mb-0 mt-1">{{data.customerName}}</p>
                      <a (click)="goto_demo(data)" class="forum-title text-primary fw-bold" style="cursor: pointer;">{{data.customerDomain}}</a>
                    </div>
                  </div>
                </td>
                <!-- class="text-center small p-3 h6" -->
                <td class="text-center small h6">{{data.concernName}}</td>
                <td class="text-center small">{{data.concernSize}}</td>
                <td class="text-center small">{{data.solutionsName[0].parentName}}</td>
                <td class="text-center small ">{{data.commenceDate |date}}</td>
                <td class="text-center small">{{data.terminationDate |date}}</td>
                <td class="text-center small"> <button type="submit" (click)="goto_demo(data)" id="submit" name="send"
                    class="btn btn-danger">Connect</button></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <!--end col-->


      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>

<!--end section-->
<!-- Start Forums -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->