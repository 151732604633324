import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-it-solution',
  templateUrl: './index-it-solution.component.html',
  styleUrls: ['./index-it-solution.component.css']
})

/***
 * It-Solution Component
 */
export class IndexItSolutionComponent implements OnInit {

  /***
   * Nav Bg Class Add
   */
  navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false

  /**
   * Blog Data
   */
  blogData = [
    {
      // image: "assets/images/blog/01.jpg",
      image: "assets/images/charja/manufacturing.png",
      title: "Manufacturing",
      like: "33",
      message: "08",
      name: "Charja Manufacturing offers an inventory flow from Raw Material warehouse to Finished Goods warehouses...",
      date: "13th August, 2019",
      url: 'page-manufacturing'
    },
    {
      // image: "assets/images/blog/02.jpg",
      image: "assets/images/charja/solutions-trading.png",
      title: "Trading",
      like: "33",
      message: "08",
      name: "Charja Trading offers an integrated Suppliers, Inventory and Customer Management System.It includes Sales...",
      date: "13th August, 2019",
      url: 'page-trading'
    },
    {
      // image: "assets/images/blog/03.jpg",
      image: "assets/images/charja/solutions-service.png",
      title: "Service",
      like: "33",
      message: "08",
      name: "Charja Service offers a fantastic platform for any type of Service oriented business. It includes Creating Service as a...",
      date: "13th August, 2019",
      url: 'page-service'
    }
    ,
    {
      // image: "assets/images/blog/03.jpg",
      image: "assets/images/charja/solutions-sme.png",
      title: "SME/SMB",
      like: "33",
      message: "08",
      name: "Charja SME offers a robust package for small and medium sized business house.Whether it is a Manufacturing...",
      date: "13th August, 2019",
      url: 'page-smesmb'
    }
  ];

  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/images/client/01.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ronny Jofra",
      designation: "C.E.O"
    },
    {
      profile: "assets/images/client/04.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Micheal Carlo",
      designation: "Director"
    },
    {
      profile: "assets/images/client/02.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aliana Rosy",
      designation: "Manager"
    },
    {
      profile: "assets/images/client/03.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sofia Razaq",
      designation: "Developer"
    }
  ];

  /**
   * Services Data
   */
  //21-Nov-23 Jubayer
  servicesData = [
    {
      // icon: "uil uil-edit-alt h1 text-primary",
      icon: "assets/images/charja/saas.png",
      title: "Software as a Service (SaaS)",
      description: "We deliver solutions using a license model called SaaS(Software As A Service). Software as a Service is a way of delivering centrally hosted applications over the internet."
    },
    {
      // icon: "uil uil-vector-square h1 text-primary",
      icon: "assets/images/charja/customizable.png",
      title: "Customizable",
      description: "Our team is ready to customize any portion of the software as per your business needs."
    },
    {
      // icon: "uil uil-lock-alt h1 text-primary",
      icon: "assets/images/charja/security.png",
      title: "Security",
      description: "We provide security to protect our solution as well as application and business data against malicious attack and other hacker risks so that the solution continues to function correctly under such potential risks."
    },
    {
      // icon: "uil uil-airplay h1 text-primary",
      icon: "assets/images/charja/privacy.png",
      title: "Privacy",
      description: "We maintain a strict privacy policy regarding our clients business data. We assure protection of our clients data from their competitors."
    },
    // {
    //   icon: "uil uil-calendar-alt h1 text-primary",
    //   title: "Daily Reports",
    //   description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    // },
    // {
    //   icon: "uil uil-clock h1 text-primary",
    //   title: "Real Time Zone",
    //   description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    // }
  ];



  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
}
