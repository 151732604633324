import { Component, OnInit } from '@angular/core';
import { LoginService } from '../http-services/login.service';

interface feature {
  icon: string;
  title: string;
  isChecked: boolean;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
  //  featuresdata: feature[] = [
  //   {
  //     icon: "monitor",
  //     title: "Fully Responsive"
  //   },
  //   {
  //     icon: "heart",
  //     title: "Browser Compatibility"
  //   },
  //   {
  //     icon: "eye",
  //     title: "Retina Ready"
  //   },
  //   {
  //     icon: "bold",
  //     title: "Based On Bootstrap 5"
  //   },
  //   {
  //     icon: "feather",
  //     title: "Feather Icons"
  //   },
  //   {
  //     icon: "code",
  //     title: "Built With SASS"
  //   },
  //   {
  //     icon: "user-check",
  //     title: "W3c Valid Code"
  //   },
  //   {
  //     icon: "git-merge",
  //     title: "Flaticon Icons"
  //   },
  //   {
  //     icon: "settings",
  //     title: "Easy to customize"
  //   }
  // ];

  featuresdata: feature[] = [
    {
      icon: "monitor",
      title: "Configuration",
      isChecked: false
    },
    {
      icon: "heart",
      title: "Financial Accounting",
      isChecked: false
    },
    {
      icon: "eye",
      title: "Check Issue Register",
      isChecked: false
    },
    {
      icon: "bold",
      title: "HRM & Payroll",
      isChecked: false
    },
    {
      icon: "feather",
      title: "Sales & Purchase",
      isChecked: false
    },
    {
      icon: "code",
      title: "Export",
      isChecked: false
    },
    {
      icon: "user-check",
      title: "Import",
      isChecked: false
    },
    {
      icon: "git-merge",
      title: "Vehicle Fleet",
      isChecked: false
    },
    {
      icon: "settings",
      title: "eCommerce",
      isChecked: false
    }
  ];

  constructor(private loginSerivice:LoginService) { }

  ngOnInit(): void {
  }


  getCheckedData(): void {
    debugger
    let d = this.featuresdata;
  }

  setIsCheckd(index: any, event: any): void {
    
    if (event.target.checked) {
      this.featuresdata.forEach((x, i) => {
        if (i == index) {
          x.isChecked = true;
        }
      });
    } else {
      this.featuresdata.forEach((x, i) => {
        if (i == index) {
          x.isChecked = false;
        }
      });
    }
    this.loginSerivice.solutions = this.featuresdata;
  }

}
