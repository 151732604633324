import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
};

@Component({
  selector: 'app-courier',
  templateUrl: './courier.component.html',
  styleUrls: ['./courier.component.css']
})

/**
 * Aboutus Component
 */
export class CourierComponent implements OnInit {

  // Set Topbar Option
  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = false;
  btnStatus: boolean = false;
  headerText: string = '';
  featureHeadText: string = '';
  featureTitle: string = '';
  servicesData: any = [];
  headerEng: string = 'A better way to manage your courier business';
  headerBan: string = 'আপনার কুরিয়ার ব্যবসা পরিচালনা করার একটি ভাল উপায় !!!';
  featureHeadEngText: string = 'We have developed the Charja Courier and Delivery Management Solutions for the benefit of the companies related to the courier and product delivery business. Using these solutions the courier and product delivery management would be easier and transparent.';
  featureHeadBanText: string = 'চর্যা কুরিয়ার ও ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারটি কুরিয়ার ও পণ্য সরবরাহ ব্যবসার সাথে যুক্ত প্রতিষ্ঠানগুলোর কথা বিবেচনায় নিয়ে তৈরি করা হয়েছে। এ সফটওয়্যারটি ব্যবহারে কুরিয়ার ও পণ্য সরবরাহ ব্যবস্থাপনা সহজ ও দ্রুততর হবে। ব্যবসায় আনবে স্বচ্ছতা। পাশাপাশি সফটওয়্যার ব্যবহারকারী প্রতিষ্ঠানের বাড়তি ব্যয়ও সংকোচন করবে।';
  featureBanTitle: string = 'চর্যা কুরিয়ার ও ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারটি’র বৈশিষ্ট্যসমূহ';
  featureEngTitle: string = 'The features of the Charja Courier and Delivery Management Solutions';


  ngOnInit(): void {
    this.setIsCheckd(false);
    // this.safeSrc = this.ds.bypassSecurityTrustResourceUrl(this.videoUrl);
  }


  setIsCheckd(isCehcked: boolean): void {
    if (isCehcked) {
      this.btnStatus = true;
      this.headerText = this.headerEng;
      this.featureHeadText = this.featureHeadEngText;
      this.featureTitle = this.featureEngTitle;
      this.servicesData = this.servicesEngData;
    } else {
      this.btnStatus = false;
      this.headerText = this.headerBan;
      this.featureHeadText = this.featureHeadBanText;
      this.featureTitle = this.featureBanTitle;
      this.servicesData = this.servicesBanData;
    }

  }

  servicesBanData = [
    {
      icon: "assets/images/charja/merchant-panel.png",
      title: "নিজস্ব মার্চেন্ট প্যানেল",
      description: "কুরিয়ার ওয়েবসাইট থেকে মার্চেন্টরা খুব সহজেই তাদের নিজস্ব একাউন্ট খুলতে পারবেন। পরবর্তীতে এই একাউন্ট ব্যবহার করে মার্চেন্ট তাদের অর্ডার তৈরি করতে পারবেন। এমনকি মার্চেন্টরা চাইলে এক্সেল সিট (Excel Sheet) থেকে একসাথে একাধিক অর্ডার আপলোড (Bulk Order Upload) করতে পারবেন।"
    },
    {
      icon: "assets/images/charja/payment-status-option.png",
      title: "মার্চেন্ট পেমেন্ট স্ট্যাটাস অপশন",
      description: "এডমিন প্যানেল থেকে কোন মার্চেন্ট কত টাকা পাবেন, কত টাকা তাকে পরিশোধ করা হয়েছে ও কত টাকা বাকি আছে তা দেখতে পারবেন। এছাড়াও কুরিয়ার কর্তৃপক্ষ মার্চেন্টের দেনা-পাওনার রিপোর্ট ডাউনলোড করে নিতে পারবেন। একইভাবে মার্চেন্ট তার নিজস্ব প্যানেলে তার অর্থের হিসাব দেখতে পাবেন।"
    },
    {
      icon: "assets/images/charja/cash-on-delivery.png",
      title: "CoD (Cash on Delivery) চার্জ, এলাকা (Area) ও ওজন (Weight) অনুযায়ী চার্জ নির্ধারণ",
      description: "এই অপশনটি পণ্য পরিবহনে তিনটি বিবেচনায় (CoD চার্জ, এলাকা ও ওজন অনুযায়ী) উপর ভিত্তি করে বিভিন্ন মূল্য নির্ধারণে বাড়তি সুবিধা দিয়ে থাকে। এতে করে প্রতি অর্ডারের জন্য আলাদাভাবে এন্ট্রি করতে হয় না এবং সময়ের সাশ্রয় হয়।"
    },
    {
      icon: "assets/images/charja/barcode-scanner.png",
      title: "স্টিকার বারকোড প্রিন্ট ও স্ক্যানিং",
      description: "রাইডারদের পণ্য ডেলিভারির সুবিধার্থে প্রতিটি পার্সেলের বারকোড সহ ডেলিভারি লিস্ট প্রিন্ট করা যাবে। কর্মদিবসের শেষে রাইডার তার ডেলিভারি লিস্টটি জমা দিলে, অফিস কর্তৃপক্ষ ডেলিভারি লিস্টের বারকোড স্ক্যান করে একাধিক অর্ডারের স্ট্যাটাস দ্রুততম সময়ের ভিতর আপডেট করতে পারবেন।"
    },

    {
      icon: "assets/images/charja/otp-verification.png",
      title: "আসল গ্রাহকের কাছে পণ্য সরবরাহ নিশ্চিতকরণ (OTP Verification)",
      description: "সফটওয়্যারটিতে আসল গ্রাহকের কাছে পণ্য সরবরাহ করা হয়েছে কিনা তা নিশ্চিত করার জন্য One Time Verification (OTP) ব্যবস্থা সংযুক্ত করা হয়েছে। তবে এই সুবিধা পেতে হলে রাইডারের কাছে অবশ্যই স্মার্ট ফোন থাকতে হবে।"
    },

    {
      icon: "assets/images/charja/fraud-detection.png",
      title: "প্রতারক চিহ্নিতকরণ (Fraud Detection)",
      description: "অনেক সময় দেখা যায় যে, কোন গ্রাহকরূপী প্রতারক পণ্য অর্ডার করার পর তা গ্রহণ করতে চায় না। এতে করে মার্চেন্টের সাথে ডেলিভারি কোম্পানিগুলোর ভুল বোঝাবুঝির সৃষ্টি হয়। ডেলিভারি কোম্পানিকে ভোগান্তিতে পড়তে হয়। এই সমস্যা এড়াতে এই সফটওয়্যারে থাকছে প্রতারক চিহ্নিতকরণ (Fraud Detection) সুবিধা। কোন গ্রাহক যদি একাধিকবার অর্ডার দিয়ে অর্ডার গ্রহণ না কোরে প্রতারণার আশ্রয় নেয় তবে অর্ডার প্লেস করার পর মার্চেন্ট তা দেখতে পাবেন।"
    },
    {
      icon: "assets/images/charja/parcel-tracking.png",
      title: "পার্সেল ট্র্যাকিং (Parcel Tracking)",
      description: "বিক্রেতা (Merchant) ও ক্রেতার জন্য পার্সেলের অবস্থান জানবার সুবিধা প্রদান প্রতিটি কুরিয়ার কর্তৃপক্ষের জন্য একটি প্রয়োজনীয় ও জরুরি সেবা হিসেবে বিবেচিত হয়ে থাকে। কাজেই চর্যা কুরিয়ার ও ডেলিভারি সফটওয়্যারে সেবাটি সংযুক্ত করা হয়েছে। এ সফটওয়্যার ব্যবহারে মার্চেন্ট তার ওর্ডার প্লেস করার পর মার্চেন্ট তার পার্সেলের অবস্থান সম্পর্কে তাৎক্ষণিকভাবে জানতে পারবেন। পাশাপাশি অর্ডার প্লেস করার পরপরই গ্রাহকদের ফোনে একটি মেসেজ চলে যাবে যেখানে ওয়েবসাইটের ঠিকানা ও অর্ডার আইডি থাকবে। এবং পরবর্তীতে ওয়েবসাইটে প্রবেশ করে গ্রাহকরা তাদের অর্ডার আইডি দিয়ে পার্সেলের বর্তমান অবস্থা সম্পর্কে জানতে পারবেন।"
    }
    // {
    //   icon: "uil uil-calendar-alt h1 text-primary",
    //   title: "Daily Reports",
    //   description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    // },
    // {
    //   icon: "uil uil-clock h1 text-primary",
    //   title: "Real Time Zone",
    //   description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    // }
  ];

  servicesEngData = [
    {
      icon: "assets/images/charja/merchant-panel.png",
      title: "Own Merchant Panel",
      description: "The merchants will be able to open their own account very easily from the Courier Website. They will use this account to place their order. Moreover if the Merchants want they can also upload bulk orders from the Excel Sheet."
    },
    {
      icon: "assets/images/charja/payment-status-option.png",
      title: "Merchant Payment Status Option",
      description: "From the Solutions Admin Panel the merchant can clearly find out the payment details. The merchant will get different financial Reports specially Receipt and Payment Report, Receivable and Payable Account Status Report etc. The Courier Authority can also download the Debit and Credit Reports."
    },
    {
      icon: "assets/images/charja/cash-on-delivery.png",
      title: "Setting the Delivery Charge on the basis of CoD (Cash on Delivery), Area and Parcel’s Weight",
      description: "This time saving option gives extra benefit to set the delivery charge depending on these criteria. This option gives a facility to the merchant to define delivery charge in a short way."
    },
    {
      icon: "assets/images/charja/barcode-scanner.png",
      title: "Sticker Barcode Print and Scanning",
      description: "To make the delivery system smooth and easy for the rider the courier authority can print the delivery list with the barcode of the parcels. After completion of the field operation when the rider will submit the delivery list the office authority will be able to scan the barcode from the list and thereby update the bulk order status within the fastest time."
    },

    {
      icon: "assets/images/charja/otp-verification.png",
      title: "Confirmation of Parcel Delivery to the Real Customer through OTP",
      description: "For verifying the real customer One Time Password (OTP) Feature is added into the Solution. But to avail this support the rider must have a Smartphone."
    },

    {
      icon: "assets/images/charja/fraud-detection.png",
      title: "Fraud Detection",
      description: "We’ve the experience that after placing the order the fraud customer refuses to accept the parcel. In these cases a misunderstanding happens between the merchant and the courier companies. An undue harassment occurs. To avoid this uncomfortable situation the Charja Courier and Delivery Management Solutions has a fraud detection feature. If any customer places multiple orders and refuses to accept the order due to fraudulence then the merchant will be able to see it after placing the order."
    },
    {
      icon: "assets/images/charja/parcel-tracking.png",
      title: "Parcel Tracking",
      description: "It’s considered as an important and necessary feature for Merchants and Customers to track or to know the location of a parcel, which is integrated into this solution. The Merchant will be able to track the parcel position at once after handing over the parcel to the Pickup Person. On the other hand after placing the order the customer will receive a sms with website address and order ID. And the customer will track the parcel's present location through the website and order ID."
    }
    // {
    //   icon: "uil uil-calendar-alt h1 text-primary",
    //   title: "Daily Reports",
    //   description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    // },
    // {
    //   icon: "uil uil-clock h1 text-primary",
    //   title: "Real Time Zone",
    //   description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    // }
  ];
  
  safeSrc: any;
  videoUrl = "https://www.youtube.com/embed/I0u2kxbYsMo";
  //videoUrl ="file:///G:/landrick _v2.5.1/mov_bbb (1).mp4";

  constructor(private modalService: NgbModal, private ds: DomSanitizer) { }



  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
