import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { emailValidator } from 'src/app/shared/http-services/app-validators';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Buffer } from "buffer";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

/**
 * Login Component
 */

export class LoginComponent implements OnInit {
  year = new Date().getFullYear();
  form: FormGroup;
  public message: any = "";
  Menuoption = 'center';
  constructor(private fb: FormBuilder, public router: Router, private spinner: NgxSpinnerService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.formInitialization();
  }


  formInitialization(): void {
    this.form = this.fb.group({
      'userName': [null, Validators.compose([Validators.required, emailValidator])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  onSaveClicked(values: Object): void {
    if (this.form.invalid) {
      return;
    }
    this.submit();
    // this.router.navigate(['/page-welcome']);
  }


  submit(): void {
    this.spinner.show();
    this.loginService.loginOne(this.form.value).subscribe({
      next: x => {
        if (x.result != null) {
          let d = Buffer.from(this.form.controls.password.value).toString('base64');
          localStorage.setItem('seal', d);
          if (x.result.tokenString != undefined) {
            this.loginService.setToken(x.result);
            this.spinner.hide();
            this.router.navigate(['/page-welcome']);
            this.formInitialization();
          } else {
            this.spinner.hide();
            this.message = "Email or Password did not match";
          }

        }
      },
      complete: () => console.log(''),
      error: err => { this.spinner.hide(); console.error('An error occurred', err) },
    });
  }

}
