import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { emailValidator } from 'src/app/shared/http-services/app-validators';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Buffer } from "buffer";
import { ToasterService } from 'src/app/shared/http-services/toast.service';
import { EmailService } from 'src/app/email/email.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

/**
 * Signup Component
 */
export class SignupComponent implements OnInit {
  form: FormGroup;
  year = new Date().getFullYear();
  constructor(private fb: FormBuilder, public router: Router, private service: LoginService,
    private spinner: NgxSpinnerService,private toastService: ToasterService,private mail: EmailService) { }

  ngOnInit(): void {
    this.formInitialization();
  }

  formInitialization(): void {
    this.form = this.fb.group({
      'customerName': [null, Validators.compose([Validators.required])],
      'customerPhone': [null, Validators.compose([Validators.required])],
      'customerEmail': [null, Validators.compose([Validators.required, emailValidator])],
      'customerPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  onSaveClicked(values: Object): void {
    if (this.form.invalid) {
      return;
    }
    this.submit();
  }

  submit(): void {
    this.spinner.show();
    let d = Buffer.from(this.form.controls.customerPassword.value).toString('base64');
    localStorage.setItem('seal', d);
    this.service.register(this.form.value).subscribe(data => {
      if (data.result.coreEssentialSerial > 0) {
        localStorage.setItem('chief', data.result.coreEssentialSerial);
        this.send_test_mail(this.form.controls.customerName.value, this.form.controls.customerEmail.value, this.form.controls.customerPhone.value);
       
      }

    }, (error) => {
      this.spinner.hide();
    });

  }

  send_test_mail(client: string, email: string, mobile: string): void {

    let emailf = Buffer.from(email).toString('base64');
    let mobilef = Buffer.from(mobile).toString('base64');

    let mailContent = this.mail.emailFormate(client, emailf, mobilef);
    let obj = {
      receiverEmail: email,
      subject: 'Account Varification',
      mailContent: mailContent
    };

    this.service.sendMail(obj).subscribe(res => {
      if (res.result) {
        this.spinner.hide();
        this.router.navigate(['/page-actions']);
        this.formInitialization();
      } else {
        this.toastService.warning("Can't send email..Please contact with administrator", "");
        this.spinner.hide();
      }
    });
  }

}
