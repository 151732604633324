import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
};

@Component({
  selector: 'app-page-import',
  templateUrl: './page-import.component.html',
  styleUrls: ['./page-import.component.css']
})

/**
 * Import Component
 */
export class PageImportComponent implements OnInit {

  // Set Topbar Option
  navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = false
  /**
   * Member Data
   */
  // memberData: member[] = [
  //   {
  //     profile: "assets/images/client/01.jpg",
  //     list: ['facebook', "instagram", "twitter", "linkedin"],
  //     name: "Ronny Jofra",
  //     designation: "C.E.O"
  //   },
  //   {
  //     profile: "assets/images/client/04.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Micheal Carlo",
  //     designation: "Director"
  //   },
  //   {
  //     profile: "assets/images/client/02.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Aliana Rosy",
  //     designation: "Manager"
  //   },
  //   {
  //     profile: "assets/images/client/03.jpg",
  //     list: ["facebook", "instagram", "twitter", "linkedin"],
  //     name: "Sofia Razaq",
  //     designation: "Developer"
  //   }
  // ];
  safeSrc: any;
  videoUrl = "https://www.youtube.com/embed/I0u2kxbYsMo";
  //videoUrl ="file:///G:/landrick _v2.5.1/mov_bbb (1).mp4";

  constructor(private modalService: NgbModal, private ds: DomSanitizer) { }

  ngOnInit(): void {
    this.safeSrc = this.ds.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
