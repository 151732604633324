<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- Hero Start -->
<section class="cover-user" style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
  <div class="container-fluid px-4" >
    <div class="row no-gutters position-relative px-4">
      <div class="col-lg-6 cover-my-30 order-2">
        <div class="cover-user-img d-flex align-items-center">
          <div class="row">
            <div class="col-12">
              <div class="card-c login-page border-0 px-4" style="z-index: 1;">
                <div class="card-body p-0">
                  <h4 class="card-title text-center pt-2" style="text-transform: uppercase;color: #13397c;">Login</h4>
                  <form [formGroup]="form" autocomplete="off" class="login-form mt-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">Your Email <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                            <input formControlName="userName" type="email" class="form-control ps-5" placeholder=""
                              name="email" required="required">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">Password <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                            <input formControlName="password" type="password" class="form-control ps-5" placeholder=""
                              required="required">
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12">
                        <div class="d-flex justify-content-between">
                          <div class="mb-3">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                              <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                            </div>
                          </div>
                          <p class="forgot-pass mb-0"><a routerLink="/recover-password" class="text-dark fw-bold">Forgot
                              password ?</a></p>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12 mb-0">
                        <div class="text-dark fw-bold" [hidden]="message== ''">
                          <p class="text-danger">{{message}}</p>
                        </div>
                        <div class="d-grid">
                          <button [disabled]="!(form.valid)" (click)="onSaveClicked(form.value)"
                            class="btn btn-primary">Sign in</button>
                        </div>
                      </div>
                      <!--end col-->

                      <!-- <div class="col-lg-12 mt-4 text-center">
                        <h6>Or Login With</h6>
                        <div class="row">
                          <div class="col-6 mt-3">
                            <div class="d-grid">
                              <a href="javascript:void(0)" class="btn btn-light"><i
                                  class="mdi mdi-facebook text-primary"></i> Facebook</a>
                            </div>
                          </div>
                          

                          <div class="col-6 mt-3">
                            <div class="d-grid">
                              <a href="javascript:void(0)" class="btn btn-light"><i
                                  class="mdi mdi-google text-danger"></i> Google</a>
                            </div>
                          </div>
                        
                        </div>
                      </div> -->
                      <!--end col-->

                      <div class="col-12 text-center">
                        <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                            routerLink="/signup" class="text-dark fw-bold">Sign Up</a></p>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div> <!-- end about detail -->
      </div> <!-- end col -->
      <div class="col-lg-6 offset-lg-6 padding-less img order-1">
        <div class="cover-user-img-c d-flex align-items-center mt-4">
          <img src="assets/images/charja/sign-in.png" class="rounded img-fluid mx-auto d-block" alt="">
        </div>
      </div>

      <!-- <div class="col-lg-6 offset-lg-6 padding-less img order-1"
        style="background-image:url('assets/images/user/01.jpg')" data-jarallax='{"speed": 0.5}'>
      </div> -->
      <!-- end col -->
    </div>
    <!--end row-->
  </div>
  <!--end container fluid-->
</section>
<!--end section-->
<!-- Hero End -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>