import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Buffer } from "buffer";
import { LoginService } from 'src/app/shared/http-services/login.service';
import { ToasterService } from 'src/app/shared/http-services/toast.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})

/**
 * Page Verification Component
 */
export class VerificationComponent implements OnInit {
  email: string = '';
  license: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private loginService: LoginService,
    private toasterSerice: ToasterService) { }

  ngOnInit(): void {
    this.inIt();
  }
  inIt(): void {
    setTimeout(() => {
      let emiil = this.activatedRoute.snapshot.queryParamMap.get("e_m") as any;
      let license = this.activatedRoute.snapshot.queryParamMap.get("li_se") as any;
      this.email = Buffer.from(emiil, 'base64').toString('binary');
      this.license = Buffer.from(license, 'base64').toString('binary');
      this.verfyClient();
    }, 5000);
  }

  verfyClient(): void {
    this.loginService.verfyClient(this.email, this.license).subscribe(res => {
      if (res.result) {
        this.router.navigate(['/solutions']);
      } else {
        this.toasterSerice.error('Oops something went wrong', 'You are not verified');
      }
    });
  }
}
