<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/" class="btn btn-icon btn-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<section class="cover-user"
    style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
    <div class="container-fluid ">
        <div class="row no-gutters position-relative ">
            <div class="col-lg-11 cover-my-30 order-2">
                <div class="cover-user-img d-flex align-items-center">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center">
                                <!-- <div class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                                    style="height: 90px; width:90px;">
                                    <i class="uil uil-thumbs-up align-middle h1 mb-0"></i>
                                </div> -->
                                <h1 class="heading mb-3">
                                    <ngx-typed-js [strings]="['we are verifying you']" [shuffle]="true" [typeSpeed]="80"
                                        [loop]="true">
                                        <h1 class="my-4 fw-bold">Please wait.. <span
                                                class="element text-primary typing"></span></h1>
                                    </ngx-typed-js>
                                </h1>


                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="col-lg-6 offset-lg-6 padding-less img order-1">
                <div class="cover-user-img-c d-flex align-items-center mt-4">
                    <img src="assets/images/charja/verification.png" class="rounded img-fluid mx-auto d-block" alt="">
                </div>
            </div>


        </div>

    </div>

</section>















<!-- Hero Start -->
<!-- <section class="bg-home bg-light d-flex align-items-center bg-soft-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center">
                    <div class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                        style="height: 90px; width:90px;">
                        <i class="uil uil-thumbs-up align-middle h1 mb-0"></i>
                    </div>
                    <h1 class="heading mb-3">
                        <ngx-typed-js [strings]="['we verify you','Hi Jubayer']"
                          [shuffle]="true" [typeSpeed]="80" [loop]="true">
                          <h1 class="my-4 fw-bold">Please wait.. <span class="element text-primary typing"></span></h1>
                        </ngx-typed-js>
                      </h1>
                    
                    <p class="text-muted para-desc mx-auto">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>
                    <a routerLink="/index" class="btn btn-soft-primary mt-3">Go To Home</a>
                </div>
            </div>
            
        </div>
        
    </div>
  
</section> -->
<!--end section-->
<!-- Hero End -->