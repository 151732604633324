import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Router } from '@angular/router';
//import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { LoginService } from './login.service';


@Injectable()

export class AuthorizationCanGuard implements CanLoad
{
    constructor
        (
        private _service: LoginService,
        private router: Router,
       )
    {}

    canLoad(): boolean
    {
        if (this._service.isLoggedIn()) {
            let token = localStorage.getItem('minor');
            const date = this.getTokenExpirationDate(token);
            if (date === undefined) {
                this.router.navigate(['/']);
                this._service.clearLocalStorageData();
                return false;
            } else {

                let condition = (date.valueOf() > new Date().valueOf());
                if (condition == true) {
                    return true;
                } else {
                    this.router.navigate(['/']);
                    this._service.clearLocalStorageData();
                    return false;
                }
            }
        }
        this.router.navigate(['/']);
        return false;
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwtDecode(token);
        if (decoded.exp === undefined) return null;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}
