<div class="row">
  <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of featuresdata; let i= index">
    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
      <div class="icon text-center rounded-circle me-3">
        <i-feather name="{{data.icon}}" class="fea icon-ex-md"></i-feather>
      </div>
      <div class="flex-1">
        <h4 class="title mb-0">{{data.title}}</h4>
      </div>
      <div class="icon text-center rounded-circle me-3">
        <input type="checkbox" class="text-primary" id="id_{{i}}"  [checked]="data.isChecked"(change)="setIsCheckd(i,$event)">
      </div>
    </div>
  </div>
  <!--end col-->

  <div class="col-12 mt-4 pt-2 text-center">
    <a routerLink="/page-trial"  class="btn btn-primary">Continue <i class="uil uil-angle-right"></i></a>
  </div>
  <!--end col-->
</div>