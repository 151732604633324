import { Injectable } from '@angular/core';

interface Mail {
  client: string,
  email: string,
  mobile: string,
  author: string
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  author: any = "Charja Team";
  year: any = new Date().getFullYear();
  origin: any = window.location.origin;
  emailFormate(client: any, email: any, mobile: any) {
    let format = `<!doctype html>
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Email Verification</title>
           
            <style>
    @-ms-viewport {
      width: device-width;
    }
    
    html {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -ms-overflow-style: scrollbar;
    }
    
    *,
    *::before,
    *::after {
      -webkit-box-sizing: inherit;
              box-sizing: inherit;
    }
    
    table {
    font-family: Nunito,sans-serif;;
        caption-side: bottom;
        border-collapse: collapse;
    }
    
    body {
        font-family: Nunito,sans-serif;
        line-height: 1.5;
        overflow-x: hidden!important;
        font-size: 1rem;
        color: #161c2d;
        background-color: #fff;
    }
    
    .container {
      margin-right: auto;
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
    }
    
    @media (min-width: 576px) {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 768px) {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 992px) {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 1200px) {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 576px) {
      .container {
        width: 540px;
        max-width: 100%;
      }
    }
    
    @media (min-width: 768px) {
      .container {
        width: 720px;
        max-width: 100%;
      }
    }
    
    @media (min-width: 992px) {
      .container {
        width: 960px;
        max-width: 100%;
      }
    }
    
    @media (min-width: 1200px) {
      .container {
        width: 1140px;
        max-width: 100%;
      }
    }
    
    .container-fluid {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
    }
    
    @media (min-width: 576px) {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 768px) {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 992px) {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 1200px) {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    .row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    
    @media (min-width: 576px) {
      .row {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
    
    @media (min-width: 768px) {
      .row {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
    
    @media (min-width: 992px) {
      .row {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
    
    @media (min-width: 1200px) {
      .row {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
    
    .no-gutters {
      margin-right: 0;
      margin-left: 0;
    }
    
    .no-gutters > .col,
    .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
    
     .col-lg-6 {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
    
    @media (min-width: 576px) {
     .col-md-8,
      .col-xl-auto {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 768px) {
       .col-lg-6 {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 992px) {
       .col-md-8 {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    
    @media (min-width: 1200px) {
     .col-lg-6,
      .col-xl-auto {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    @media (min-width: 576px) {
    }
    
    @media (min-width: 768px) {
    
    
      .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
            -ms-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
    
    }
    
    @media (min-width: 992px) {
    
    
      .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
      }
    
              
        .btn-primary {
        color: #fff;
        background-color: #2f55d4;
        border-color: #2f55d4;
        }
        
        
        
            </style>
          </head>
          <body>
            <section style="align-items: center; padding: 50px 0;">
        <div class="container">
            <div class="row" style="justify-content: center;">
                <div class="col-lg-6 col-md-8">
                    <table
                        style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                        <thead>
                            <tr
                                style="background-color: #2f55d4; padding: 3px 0;  text-align: center; color: #fff;  font-weight: 700; letter-spacing: 1px;">
                                <th scope="col"><img src="https://jimamu.com/IDPIC/Image/charja_logo_light.png" style="width: 300px;height:80px;display: block;margin-left: auto;margin-right: auto;" alt="Logo" title="Logo" /></th>
                            </tr>
                        </thead>
    
                        <tbody>
                        <tr>
                            <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 500;">
                                Hello, ${client}
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 15px 24px 15px; color: #8492a6;font-size: 1rem;">
                                We’re excited to welcome you to Charja Solutions ! Before you begin your journey, we need to verify your account. Follow these steps to complete the verification process. Click the link below to verify your account.
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px;">
                                <a href="${this.origin}/verification?e_m=${email}&li_se=${mobile}" target="_blank" class="btn-primary"
                                    style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 16px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 600; border-radius: 6px;color: #fff; background-color: #2f55d4; border-color: #2f55d4;">Confirm
                                    Email Address</a>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 15px 24px 0; color: #8492a6;font-size: 1rem;">
                               If you encounter any issues or have questions, our support team is here to help. Simply reply to this email or reach out to us at : support@charja.solutions or +8802223366363
                            </td>
                        </tr>

                        <tr>
						
                            <td style="padding: 15px 24px 15px; color: #8492a6;">
                                Charja <br> Support Team
								
                            </td>
                        </tr>

                        <tr>
                            <td
                                style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                                © ${this.year} Charja.
								
                            </td>
							
                        </tr>
                    </tbody>
                    </table>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
            
          </body>
        </html>`
    return format;
  }

  /// 07-Dec -23

  receverPassword(client: any, email: any, mobile: any) {
    let format = `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Email Verification</title>
     
      <style>
@-ms-viewport {
width: device-width;
}

html {
-webkit-box-sizing: border-box;
        box-sizing: border-box;
-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
-webkit-box-sizing: inherit;
        box-sizing: inherit;
}

table {
font-family: Nunito,sans-serif;;
  caption-side: bottom;
  border-collapse: collapse;
}

body {
  font-family: Nunito,sans-serif;
  line-height: 1.5;
  overflow-x: hidden!important;
  font-size: 1rem;
  color: #161c2d;
  background-color: #fff;
}

.container {
margin-right: auto;
margin-left: auto;
padding-right: 15px;
padding-left: 15px;
}

@media (min-width: 576px) {
.container {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 768px) {
.container {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 992px) {
.container {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 1200px) {
.container {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 576px) {
.container {
  width: 540px;
  max-width: 100%;
}
}

@media (min-width: 768px) {
.container {
  width: 720px;
  max-width: 100%;
}
}

@media (min-width: 992px) {
.container {
  width: 960px;
  max-width: 100%;
}
}

@media (min-width: 1200px) {
.container {
  width: 1140px;
  max-width: 100%;
}
}

.container-fluid {
width: 100%;
margin-right: auto;
margin-left: auto;
padding-right: 15px;
padding-left: 15px;
}

@media (min-width: 576px) {
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 768px) {
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 992px) {
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 1200px) {
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
}

.row {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}

@media (min-width: 576px) {
.row {
  margin-right: -15px;
  margin-left: -15px;
}
}

@media (min-width: 768px) {
.row {
  margin-right: -15px;
  margin-left: -15px;
}
}

@media (min-width: 992px) {
.row {
  margin-right: -15px;
  margin-left: -15px;
}
}

@media (min-width: 1200px) {
.row {
  margin-right: -15px;
  margin-left: -15px;
}
}

.no-gutters {
margin-right: 0;
margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
padding-right: 0;
padding-left: 0;
}

.col-lg-6 {
position: relative;
width: 100%;
min-height: 1px;
padding-right: 15px;
padding-left: 15px;
}

@media (min-width: 576px) {
.col-md-8,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 768px) {
 .col-lg-6 {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 992px) {
 .col-md-8 {
  padding-right: 15px;
  padding-left: 15px;
}
}

@media (min-width: 1200px) {
.col-lg-6,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
}
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {


.col-md-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.666667%;
      -ms-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

}

@media (min-width: 992px) {


.col-lg-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

        
  .btn-primary {
  color: #fff;
  background-color: #2f55d4;
  border-color: #2f55d4;
  }
  
  
  
      </style>
    </head>
    <body>
      <section style="align-items: center; padding: 50px 0;">
  <div class="container">
      <div class="row" style="justify-content: center;">
          <div class="col-lg-6 col-md-8">
              <table
                  style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                  <thead>
                      <tr
                          style="background-color: #2f55d4; padding: 3px 0;  text-align: center; color: #fff;  font-weight: 700; letter-spacing: 1px;">
                          <th scope="col"><img src="https://jimamu.com/IDPIC/Image/charja_logo_light.png" style="width: 300px;height:80px;display: block;margin-left: auto;margin-right: auto;" alt="Logo" title="Logo" /></th>
                      </tr>
                  </thead>

                  <tbody>
                  <tr>
                      <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 500;">
                          Hello, ${client}
                      </td>
                  </tr>
                  <tr>
                      <td style="padding: 15px 24px 15px; color: #8492a6;font-size: 1rem;">
                          A request has been received to change your password for your account.Click the link below to change your password.
                      </td>
                  </tr>

                  <tr>
                      <td style="padding: 15px 24px;">
                          <a href="${this.origin}/re-password?el_m=${email}&cor_se=${mobile}" target="_blank" class="btn-primary"
                              style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 16px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 600; border-radius: 6px;color: #fff; background-color: #2f55d4; border-color: #2f55d4;">
                             Reset Password</a>
                      </td>
                  </tr>

                  <tr>
                      <td style="padding: 15px 24px 0; color: #8492a6;font-size: 1rem;">
                         If you encounter any issues or have questions, our support team is here to help. Simply reply to this email or reach out to us at : support@charja.solutions or 09643226363
                      </td>
                  </tr>

                  <tr>
      
                      <td style="padding: 15px 24px 15px; color: #8492a6;">
                          Charja <br> Support Team
          
                      </td>
                  </tr>

                  <tr>
                      <td
                          style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                          © ${this.year} Charja.
          
                      </td>
        
                  </tr>
              </tbody>
              </table>
          </div>
          <!--end col-->
      </div>
      <!--end row-->
  </div>
  <!--end container-->
</section>
      
    </body>
  </html>`
    return format;
  }






}
