<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- Hero Start -->
<section class="cover-user bg-home" style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
  <div class="container-fluid px-0">
    <div class="row g-0 position-relative">
      <div class="col-lg-6 cover-my-30 order-2">
        <div class="cover-user-img d-flex align-items-center">
          <div class="row">
            <div class="col-12">
              <div class="card-c border-0 px-4" style="z-index: 1">
                <div class="card-body p-0 mt-4">
                  <h4 class="card-title text-center" style="text-transform: uppercase;color: #13397c;">Recover Account
                  </h4>
                  <form [formGroup]="form" class="login-form mt-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <p class="text-muted">Please enter your email address. You will receive a link to create a new
                          password via email.</p>
                        <div class="mb-3">
                          <label class="form-label">Email address <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                            <input formControlName="email" type="email" class="form-control ps-5"
                              placeholder="Enter Your Email Address" name="email" required="">
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="d-grid">
                          <button [disabled]="!(form.valid)" (click)="onSaveClicked(form.value)"
                            class="btn btn-primary ">Send</button>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="mx-auto">
                        <p class="mb-0 mt-3"><small class="text-dark me-2">Remember your password ?</small> <a
                            routerLink="/login" class="text-dark fw-bold">Sign in</a></p>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div> <!-- end about detail -->
      </div> <!-- end col -->


      <div class="col-lg-6 offset-lg-6 padding-less img order-1">
        <div class="cover-user-img-c d-flex align-items-center ">
          <img src="assets/images/charja/forgot-password.png" class="rounded img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!-- <div class="col-lg-7 offset-lg-4 padding-less img order-1"
        style="background-image:url('assets/images/user/03.jpg')" data-jarallax='{"speed": 0.5}'>
      </div> -->
      <!-- end col -->
    </div>
    <!--end row-->
  </div>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Please wait...</p>
  </ngx-spinner>
  <!--end container fluid-->
</section>
<!--end section-->
<!-- Hero End -->