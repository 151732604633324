import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { SelectionModel } from '@angular/cdk/collections';

interface feature {
  icon: string;
  title: string;
  isChecked: boolean;
};

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})

/**
 * Solutions Component
 */
export class SolutionsComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  /**
   * nav Light class Add
   */
  navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false;
  isbtn: boolean = true;
  modules: any = [];
  backUpModules: any = [];



  constructor(private modalService: NgbModal, private loginService: LoginService) { }



  ngOnInit(): void {
    this.getFeatues();
  }

  getFeatues(): void {
    this.loginService.getDemoFeatures().subscribe(res => {
      if (res.result.length > 0) {
        let array = [];
        this.modules = res.result;

        // let reverse = this.backUpModules.reverse();
        // let [copp, courier, eCom, ...restEle] = reverse;
        // let moduleCollection = restEle.reverse();
        // copp.icon = this.icones.filter((d: { id: any }) => d.id == copp.id)[0]?.icon;
        // courier.icon = this.icones.filter((d: { id: any }) => d.id == courier.id)[0]?.icon;
        // eCom.icon = this.icones.filter((d: { id: any }) => d.id == eCom.id)[0]?.icon;
        // moduleCollection.forEach((el: { icon: any; id: any; }) => {
        //   el.icon = this.icones.filter((d: { id: any }) => d.id == el.id)[0].icon
        // });

        // array.push({ head: 'ERP', collections: moduleCollection });
        // array.push({ head: 'eCommerce', collections: [eCom] });
        // array.push({ head: 'Cooperative', collections: [copp] });
        // this.modules = array;

        // const groupByCategory = Object.groupBy(this.modules, product => {
        //   return product.category;
        // });

        // this.modules.forEach((el: { icon: any; id: any; }) => {
        //   el.icon = this.icones.filter((d: { id: any }) => d.id == el.id)[0].icon
        // });

        // if (this.landingService.selectedModule.length > 0) {
        //     this.spread = 80;
        //     this.modules.forEach((d: { id: any, isChecked: boolean }) => {
        //         if (this.landingService.selectedModule.filter((el: { id: any }) => el.id == d.id).length > 0) {
        //             d.isChecked = this.isTrue;
        //             this.selection.select(d);
        //         }
        //     });
        // }
      }

      // const filterMessage = res.result.filter((el: { id: any; }) => {
      //       return this.icones.some((m: { id: any; }) => {
      //         return m.id == el.id ;
      //     });
      // });
    });
  }


  getCheckedData(): void {
    debugger

  }

  setIsCheckd(index: any, event: any, parentInd: any): void {
    debugger
    // if (event.target.checked) {
    //   this.modules.forEach((x, i) => {
    //     if (i == index) {
    //       x.isChecked = true;
    //     }
    //   });
    // } else {
    //   this.modules.forEach((x, i) => {
    //     if (i == index) {
    //       x.isChecked = false;
    //     }
    //   });
    // }

    let d = this.modules[parentInd];

    if (event.target.checked) {
      this.modules[parentInd].collections.forEach((x: { isChecked: boolean; }, i: any) => {
        if (i == index) {
          x.isChecked = true;
        }
      });
    } else {
      this.modules[parentInd].collections.forEach((x: { isChecked: boolean; }, i: any) => {
        if (i == index) {
          x.isChecked = false;
        }
      });
    }

    this.isbtn = this.modules.filter((x: { isChecked: boolean }) => x.isChecked == true).length > 0 ? false : true;
    this.loginService.solutions = this.modules;

  }

  check(index: any, data: any, event: any): void {
    if (event.target.checked) {
      this.modules.filter((x: { solutionCollectionSerial: any }) => x.solutionCollectionSerial != data.solutionCollectionSerial).forEach((y: { solutions: { isDesibled: any; }[]; }) => {
        y.solutions.forEach((z: { isDesibled: any }) => {
          z.isDesibled = true;
        });
      });

    } else {
      if (this.selection.selected.length > 0) {
        this.modules.filter((x: { solutionCollectionSerial: any }) => x.solutionCollectionSerial != data.solutionCollectionSerial).forEach((y: { solutions: { isDesibled: any; }[]; }) => {
          y.solutions.forEach((z: { isDesibled: any }) => {
            z.isDesibled = true;
          });
        });
      } else {
        this.modules.filter((x: { solutionCollectionSerial: any }) => x.solutionCollectionSerial != data.solutionCollectionSerial).forEach((y: { solutions: { isDesibled: any; }[]; }) => {
          y.solutions.forEach((z: { isDesibled: any }) => {
            z.isDesibled = false;
          });
        });
      }


    }


    if (this.selection.selected.length > 0) {
      this.isbtn = false;
      this.loginService.solutions = this.selection.selected;
    } else {
      this.isbtn = true;
      this.loginService.solutions = [];
    }
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
