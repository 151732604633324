import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { jwtDecode } from 'jwt-decode';


@Injectable({
    providedIn: 'root',
})
export class AuthRouterGuard {
    constructor(private _service: LoginService, public router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

        if (this._service.isLoggedIn()) {
            let token = localStorage.getItem('minor');
            const date = this.getTokenExpirationDate(token);
            if (date === undefined) {
                this.router.navigate(['/login']);
                this._service.clearLocalStorageData();
                return false;
            } else {
                let condition = (date.valueOf() > new Date().valueOf());
                if (condition == true) {
                    //return this.checkLogin(state.url);
                    return true;
                } else {
                    this.router.navigate(['/login']);
                    this._service.clearLocalStorageData();
                    return false;
                }
            }
        }
        this.router.navigate(['/login']);
        return false;
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwtDecode(token);
        if (decoded.exp === undefined) return null;
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }


    // checkLogin(url: string): Observable<boolean> {
    //     let model = {
    //         state: url
    //     }
    //     return this._service.RouteCheck(model).map(res => {
    //         if (res.result == true) {
    //             return true;
    //         }
    //         else {
    //             this.router.navigate(['/session/loginV2']);
    //             this._service.clearLocalStorageData();
    //             return false;
    //         }
    //       },
    //         error => {
    //             this.router.navigate(['/session/loginV2']);
    //             this._service.clearLocalStorageData();
    //             return false;
    //         });
    // }
}