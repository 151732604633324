<!-- Hero Start -->
<!-- <section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/bg2.png') center center;"> -->
<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/charja/bg_1.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mb-md-5 pb-md-5">
          <h4 class="text-white-50">We are creative</h4>
          <h4 class="heading text-white mb-3 title-dark"> Best IT Solution <br> Company </h4>

          <!-- <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
            -->

          <div class="mt-4 pt-2 pt-2">
            <div class="mt-4 pt-2 pt-2">
              <div class="mt-4 pt-2 pt-2">
                <a routerLink="/login" class="btn btn-light-c">Get Started</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
        <img src="assets/images/charja/hero_1.png" class="img-fluid" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<!-- <section class="py-4 bg-light">
  <div class="container">
    <app-clients-logo  brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
   
  </div>
 
</section> -->
<!--end section-->
<!-- Partners End -->

<!-- 21-Nov-23 Jubaeyr -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" style="text-transform: uppercase;color: #13397c;">Why Charja Solutions</h4>
          <!-- para-desc -->
          <p class="text-muted mx-auto mb-0" style="text-align: justify;"><span class="text-primary fw-bold">Charja
              Solutions Limited</span>
            was formed with a mission to build a new horizon for the clients with their innovative ideas and
            technologies. A new start is always tough but if one has the strength and courage along with a hard working
            team like us they will surely reach the goal.
            Charja team provides IT consultancy, software development and management. Our clients are always our
            priority. Business application is our forte. We specialize in Enterprise Resource Planning, Customer
            Relationship Management, Human Resource Management and Retail Sales at present. If you want to make a change
            in your working arena with information technology you can surely rely on us.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>


<!-- <section class="section"> -->
<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4" style="text-transform: uppercase;color: #13397c;">Product Philosophy</h4>
        <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Charja</span>
          that can provide everything you need to generate awareness,
          drive traffic, connect.</p> -->
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->

  <app-services [servicesData]="servicesData" service_class="features feature-primary mt-5"></app-services>
</div>
<!--end container-->
<!-- </section> -->

<!-- ........ -->

<!-- 21-Nov-23 Jubaeyer -->

<section class="section ">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title fw-bold mb-4" style="text-transform: uppercase;color: #13397c;">Product Version</h4>
          <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <!-- <div class="card blog rounded border-0 shadow overflow-hidden"> -->
        <div class="rounded border-0  overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/charja/online-hosting.png" class="card-img-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <!-- <div class="card-body content">
            <h5><a href="javascript:void(0)" class="card-title title text-dark">Conversations, Feedback, Recognition</a>
            </h5>
            <div class="post-meta d-flex justify-content-between mt-3">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                      class="uil uil-heart me-1"></i>33</a></li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                      class="uil uil-comment me-1"></i>08</a></li>
              </ul>
              <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                  class="uil uil-angle-right-b align-middle"></i></a>
            </div>
          </div> -->
          <!-- <div class="author">
            <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
            <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
          </div> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4" style="text-transform: uppercase;color: #4b6cda;">Online Hosting</h4>
          <p class="text-muted" style="text-align: justify;">This application is hosted on the Charja’s servers and
            accessed through a web browser.
            Customers need to purchase license only.</p>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/charja/premise-hosting.png" class="img-fluid rounded" alt="">
        <!-- <img src="assets/images/travel/3.jpg" class="img-fluid rounded shadow-md" alt=""> -->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4" style="text-transform: uppercase;color: #4b6cda;">On Premise Hosting</h4>
          <p class="text-muted" style="text-align: justify;">On Premise application is to be deployed locally on a
            Customer’s own server. Customer
            will purchase only the license to use the software. They will have to provide the network infrastructure,
            uninterrupted power supply, Operating System & Database Software.</p>
        </div>

        <!-- <div class="faq-content mt-4 pt-2">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0">How does it work ?
                  </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                  but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>





  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" style="text-transform: uppercase;color: #13397c;">Industry Vertical</h4>
          <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p> -->
        </div>
      </div>

    </div>

    <app-blog [blogData]="blogData"></app-blog>
  </div>

</section>

<!-- ......... -->

<!-- <section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What we do ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      
    </div>


    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Hign Performance</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
    

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Best Securities</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Trusted Service</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
    

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Info Technology</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
   

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>24/7 Support</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
   

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>IT Management</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
     

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Certified Company</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
   

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Data Analytics</h5>
            <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
          </div>
        </div>
      </div>
     

      <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript:void(0)" class="btn btn-primary">See more
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </a>
      </div>
      
    </div>
   
  </div>
 

  
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>
         
          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
             

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      
      </div>
     

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Our Story</h4>
            <p class="text-muted para-desc">Start working with <span class="text-primary fw-bold">Charja</span> that
              can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to
              have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin. It contains a series of real Latin words.</p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Fully Responsive</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Multiple Layouts</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Suits Your Style</li>
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a href="javascript:void(0)" target="_blank" class="btn btn-primary m-1 me-2">Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
   
  </div>

</section> -->


<!-- Start -->
<section class="section pt-0" style="padding: 0px 0;">
  <div class="container">
    <!-- <div class="p-4 rounded shadow bg-primary position-relative" style="z-index: 1;">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="progress-box">
            <h6 class="title text-white">Web Designing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:84%;">
                <div class="progress-value d-block text-white-50 h6">84%</div>
              </div>
            </div>
          </div>
        
          <div class="progress-box mt-4">
            <h6 class="title text-white">Web Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:75%;">
                <div class="progress-value d-block text-white-50 h6">75%</div>
              </div>
            </div>
          </div>
          
          <div class="progress-box mt-4">
            <h6 class="title text-white">Game Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:79%;">
                <div class="progress-value d-block text-white-50 h6">79%</div>
              </div>
            </div>
          </div>
         
        </div>
      

        <div class="col-md-6 col-12">
          <div class="progress-box mt-4 mt-sm-0">
            <h6 class="title text-white">App Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:84%;">
                <div class="progress-value d-block text-white-50 h6">84%</div>
              </div>
            </div>
          </div>
        
          <div class="progress-box mt-4">
            <h6 class="title text-white">Digital Marketing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:75%;">
                <div class="progress-value d-block text-white-50 h6">75%</div>
              </div>
            </div>
          </div>
        
          <div class="progress-box mt-4">
            <h6 class="title text-white">Full stack Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:79%;">
                <div class="progress-value d-block text-white-50 h6">79%</div>
              </div>
            </div>
          </div>
       
        </div>
       
      </div>
     
    </div> -->

    <div class="row mt-4 pt-2 position-relative bg-light" id="counter" style="z-index: 1;">
      <div class="col-md-4 col-6 mt-4 pt-2">
        <div class="center-line avatar avatar-small"
          style="background: url('assets/images/charja/homepage-happy-clients.png') ;background-repeat: no-repeat;">
          <div class="txt-line" style="display: table-column;">
            <h5 class="mb-0 mt-4"><span class="counter-value" [countUp]="97">3</span>%</h5>
            <!-- text-muted -->
            <h6 class="counter-head">Happy Client</h6>
          </div>
        </div>

      </div>

      <div class="col-md-4 col-6 mt-4 pt-2">
        <div class="center-line avatar avatar-small"
          style="background: url('assets/images/charja/homepage-achievements-awards.png') ;background-repeat: no-repeat;">
          <div class="txt-line" style="display: table-column;">
            <h5 class="mb-0 mt-4"><span class="counter-value" [countUp]="15" [options]="option"></span>+</h5>
            <h6 class="counter-head ">Awards</h6>
          </div>
        </div>

      </div>
      <!-- <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="center-line avatar avatar-small"
          style="background: url('assets/images/charja/homepage-job-placement.png') ;background-repeat: no-repeat;">
          <div class="txt-line" style="display: table-column;">
            <h5 class="mb-0 mt-4"><span class="counter-value" [countUp]="2" [options]="option"></span>K</h5>
            <h6 class="counter-head">Job Placement</h6>
          </div>
        </div>

      </div> -->

      <div class="col-md-4 col-6 mt-4 pt-2">
        <div class="center-line avatar avatar-small"
          style="background: url('assets/images/charja/homepage-project-complete.png') ;background-repeat: no-repeat;">
          <div class="txt-line" style="display: table-column;">
            <h5 class="mb-0 mt-4"><span class="counter-value" [countUp]="98" [options]="option"></span>%</h5>
            <h6 class="counter-head">Project Complete</h6>
          </div>
        </div>

      </div>


      <!-- <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/charja/homepage-project-complete.png" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="98" [options]="option"></span>%</h2>
          <h6 class="counter-head text-muted">Project Complete</h6>
        </div>
       
      </div> -->
    </div>
    <!--end row-->
    <!-- <div class="feature-posts-placeholder bg-light"></div> -->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section" style="padding-bottom: 15px 0;background: url('assets/images/charja/bg_1.png') center center;">
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Work Process</h6>
          <h4 class="title mb-4">How do we works ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
     
    </div>
  

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Discussion</h5>
            <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated</p>
          </div>
        </div>
      </div>
  
      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Strategy & Testing</h5>
            <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p>
          </div>
        </div>
      </div>
     

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Reporting</h5>
            <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p>
          </div>
        </div>
      </div>
     
    </div>
   
  </div> -->

  <!-- class="container mt-100 mt-60" -->
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2-c">
          <h4 class="title mb-4" style="text-transform: uppercase;color: #13397c;">Our Mind Power</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
     
    </div>
    
    <app-member [memberData]="memberData"></app-member>
    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class=" team text-center border-0">
          <div class="img-fluid avatar avatar-ex-large outer ">
            <div class="img-fluid avatar avatar-ex-large inner ">

            </div>
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item ms-1" *ngFor="let item of data.list"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon">
                  <i-feather name="{{item}}" class="icons"></i-feather>
                </a></li>
            </ul>

          </div>
          <div class="card-body py-3 px-0 content">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
            <small class="designation text-muted">{{data.designation}}</small>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!--end container-->
</section>
<!--end section-->

<!-- <section class="section bg-light">
  <div class="container">
    <div class="row align-items-center mb-4 pb-2">
      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <h6 class="text-primary">Blog</h6>
          <h4 class="title mb-4 mb-lg-0">Reads Our Latest <br> News & Blog</h4>
        </div>
      </div>
     

      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
     
    </div>
   

    <app-blog [blogData]="blogData"></app-blog>
    
  </div>

</section> -->
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->