<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Checkouts </h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="index-shop">Shop</a></li>
          <li class="breadcrumb-item active" aria-current="page">Checkouts</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-lg-8">
        <div class="card rounded shadow p-4 border-0">
          <h5 class="mb-3">Billing Details :</h5>

          <form [formGroup]="validationform" (ngSubmit)="validSubmit()" class="needs-validation">
            <div class="row g-3">
              <div class="col-sm-6">
                <label class="form-label">First name</label>
                <input name="name" id="firstname" type="text" class="form-control" placeholder="First Name :" required formControlName="firstName" [ngClass]="{'is-invalid': submit && form['firstName'].errors}">
                <div class="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>

              <div class="col-sm-6">
                <label for="lastName" class="form-label">Last name</label>
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" value="" formControlName="lastName" [ngClass]="{'is-invalid': submit && form['lastName'].errors}" required>
                <div class="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <label for="username" class="form-label">Username</label>
                <div class="input-group has-validation">
                  <span class="input-group-text bg-light text-muted border">@</span>
                  <input type="text" class="form-control" id="username" placeholder="Username" formControlName="userName" [ngClass]="{'is-invalid': submit && form['userName'].errors}" required>
                  <div class="invalid-feedback"> Your username is required. </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <label for="email" class="form-label">Email <span class="text-muted">(Optional)</span></label>
                <input type="email" class="form-control" id="email" placeholder="you@example.com" formControlName="email" [ngClass]="{'is-invalid': submit && form['email'].errors}">
                <div class="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <label for="address" class="form-label">Address</label>
                <input type="text" class="form-control" id="address" placeholder="1234 Main St" formControlName="address" [ngClass]="{'is-invalid': submit && form['address'].errors}" required>
                <div class="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <label for="address2" class="form-label">Address 2 <span class="text-muted">(Optional)</span></label>
                <input type="text" class="form-control" id="address2" placeholder="Apartment or suite">
              </div>
              <!--end col-->

              <div class="col-md-5">
                <label for="country" class="form-label">Country</label>
                <select class="form-select form-control" id="country" formControlName="country" [ngClass]="{'is-invalid': submit && form['country'].errors}" required>
                  <option selected>India</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">&Aring;land Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                </select>
                <div class="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>

              <div class="col-md-4">
                <label for="state" class="form-label">State</label>
                <select class="form-select form-control" id="state" formControlName="state" [ngClass]="{'is-invalid': submit && form['state'].errors}" required>
                  <option value="">Choose...</option>
                  <option>California</option>
                </select>
                <div class="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>

              <div class="col-md-3">
                <label for="zip" class="form-label">Zip</label>
                <input type="text" class="form-control" id="zip" formControlName="zip" [ngClass]="{'is-invalid': submit && form['zip'].errors}" placeholder="" required>
                <div class="invalid-feedback">
                  Zip code required.
                </div>
              </div>
              <!--end col-->
            </div>
            <div class="form-check mt-4 pt-4 border-top">
              <input type="checkbox" class="form-check-input" id="same-address">
              <label class="form-check-label" for="same-address">Shipping address is the same as my
                billing address</label>
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="save-info">
              <label class="form-check-label" for="save-info">Save this information for next
                time</label>
            </div>

            <h4 class="mb-3 mt-4 pt-4 border-top">Payment</h4>

            <div class="my-3">
              <div class="form-check">
                <input id="credit" name="paymentMethod" type="radio" class="form-check-input" checked required>
                <label class="form-check-label" for="credit">Credit card</label>
              </div>
              <div class="form-check">
                <input id="debit" name="paymentMethod" type="radio" class="form-check-input" required>
                <label class="form-check-label" for="debit">Debit card</label>
              </div>
              <div class="form-check">
                <input id="paypal" name="paymentMethod" type="radio" class="form-check-input" required>
                <label class="form-check-label" for="paypal">PayPal</label>
              </div>
            </div>

            <div class="row gy-3">
              <div class="col-md-6">
                <label for="cc-name" class="form-label">Name on card</label>
                <input type="text" class="form-control" id="cc-name" placeholder="" formControlName="cardName" [ngClass]="{'is-invalid': submit && form['cardName'].errors}" required>
                <small class="text-muted">Full name as displayed on card</small>
                <div class="invalid-feedback">
                  Name on card is required
                </div>
              </div>

              <div class="col-md-6">
                <label for="cc-number" class="form-label">Credit card number</label>
                <input type="text" class="form-control" id="cc-number" placeholder="" formControlName="cardNo" [ngClass]="{'is-invalid': submit && form['cardNo'].errors}" required>
                <div class="invalid-feedback">
                  Credit card number is required
                </div>
              </div>

              <div class="col-md-3 mb-3">
                <label for="cc-expiration" class="form-label">Expiration</label>
                <input type="text" class="form-control" id="cc-expiration" placeholder="" formControlName="expiredate" [ngClass]="{'is-invalid': submit && form['expiredate'].errors}" required>
                <div class="invalid-feedback">
                  Expiration date required
                </div>
              </div>

              <div class="col-md-3 mb-3">
                <label for="cc-cvv" class="form-label">CVV</label>
                <input type="text" class="form-control" id="cc-cvv" placeholder="" formControlName="cvv" [ngClass]="{'is-invalid': submit && form['cvv'].errors}" required>
                <div class="invalid-feedback">
                  Security code required
                </div>
              </div>
            </div>

            <button class="w-100 btn btn-primary" type="submit">Continue to checkout</button>
            <!--end row-->
          </form>
          <!--end form-->
        </div>
      </div>
      <!--end col-->

      <div class="col-md-5 col-lg-4 order-md-last">
        <div class="card rounded shadow p-4 border-0">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="h5 mb-0">Your cart</span>
            <span class="badge bg-primary rounded-pill">3</span>
          </div>
          <ul class="list-group mb-3 border">
            <li class="d-flex justify-content-between lh-sm p-3 border-bottom">
                <div>
                    <h6 class="my-0">Product name</h6>
                    <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">$12</span>
            </li>
            <li class="d-flex justify-content-between lh-sm p-3 border-bottom">
                <div>
                    <h6 class="my-0">Second product</h6>
                    <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">$8</span>
            </li>
            <li class="d-flex justify-content-between lh-sm p-3 border-bottom">
                <div>
                    <h6 class="my-0">Third item</h6>
                    <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">$5</span>
            </li>
            <li class="d-flex justify-content-between bg-light p-3 border-bottom">
                <div class="text-success">
                    <h6 class="my-0">Promo code</h6>
                    <small>EXAMPLECODE</small>
                </div>
                <span class="text-success">−$5</span>
            </li>
            <li class="d-flex justify-content-between p-3">
                <span>Total (USD)</span>
                <strong>$20</strong>
            </li>
        </ul>

        <form>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Promo code">
                <button type="submit" class="btn btn-secondary">Redeem</button>
            </div>
        </form>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->