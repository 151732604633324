import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { Observable } from 'Rxjs/Observable';
import { catchError, timeout } from 'rxjs/operators';
import { ToasterService } from './toast.service';
//import Swal from 'sweetalert2'
import { LoginService } from './login.service';


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    private authService: LoginService;
    public toastService: ToasterService;
    constructor(private injector: Injector, private router: Router, private tokenExtractor: HttpXsrfTokenExtractor) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;
        if (this.authService === undefined) {
            this.authService = this.injector.get(LoginService);
        }
        if (this.authService !== undefined) {
            let token = this.authService.getToken();
            if (token !== "") {
                let tokenValue = "Bearer " + token;
                requestToForward = req.clone({ setHeaders: { "Authorization": tokenValue, "Access-Control-Allow-Origin": "true" } });
            }
        } else {
            console.debug("OidcSecurityService undefined: NO auth header!");
        }
        return next.handle(requestToForward).pipe(
            timeout(500000),
            catchError((error: HttpErrorResponse) => {
                let errMsg = '';
                if (error.error != null) {

                    // Client Side Error
                    if (error.error instanceof ErrorEvent) {
                        errMsg = `Error: ${error.error.message}`;
                    }
                    else {  // Server Side Error
                        //   errMsg = `Error Code: ${error.status},  Message: ${error.error.message}`;

                        errMsg = `Message: ${error.error.message}`;
                    }
                } else {
                    if (error.status == 401) {
                        this.authService.clearLocalStorageData();
                        errMsg = `Error Code: ${error.status},  Message: 'Unauthorize user.'`;
                        this.router.navigate(['/session/loginV2']);
                    } else if (error.status == 403) {
                        this.authService.clearLocalStorageData();
                        errMsg = `Error Code: ${error.status},  Message: 'Don't have permission to access.'`;
                        this.router.navigate(['/session/lockscreenV2']);
                    } else if (error.status == 500) {
                        this.authService.clearLocalStorageData();
                        errMsg = `Error Code: ${error.status},  Message: 'Don't have permission to access.'`;
                        this.router.navigate(['/session/loginV2']);
                    }
                }

                this.toastService = this.injector.get(ToasterService);

                //              this.toastService.finalerror(errMsg, "error");

                // Swal.fire({
                //     icon: 'error',
                //     title: 'Error...',
                //     text: errMsg,
                //     footer: 'Please try again'
                // })
                return throwError(errMsg);
            })
        );
    }

}
