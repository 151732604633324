<!-- Start Home -->
<section class="bg-home d-flex align-items-center bg-animation-left task-management-home" style="height: auto;"
  id="home">
  <div class="container position-relative" style="z-index: 1;">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading text-white title-dark mb-3" style="font-size:38px !important">আপনার কুরিয়ার ব্যবসা
            পরিচালনা করার একটি ভাল উপায়</h1>
          <!-- <h1 class="heading text-white title-dark mb-3">A Better Way to Manage your Coureier Business</h1> -->
          <!-- <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our expertise on designing
            and managing conversion centered bootstrap v5 html page.</p> -->
          <!-- <div class="text-center subcribe-form mt-4 pt-2">
            <form>
              <div class="form-group mb-0">
                <input type="email" id="email" name="email" class="border rounded-pill" required
                  placeholder="Email Address">
                <button type="submit" class="btn btn-pills btn-primary">Get Started</button>
              </div>
            </form>
          </div> -->
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-10 text-center">
            <div class="home-dashboard">
              <img src="assets/images/task/laptop.png" alt="" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Home -->

<!-- Features Start -->
<section class="section mt-0">
  <!-- <div class="container">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
  </div> -->
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img src="assets/images/task/timeline.png" class="img-fluid shadow rounded-md" alt="">
      </div>
     

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h1 class="text-primary"><i class="uil uil-schedule"></i></h1>
          <h4 class="title mb-4">Track your timeline</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <ul class="list-unstyled text-muted">
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Digital Marketing Solutions for Tomorrow
            </li>
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Create your own skin to match your brand
            </li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
    
    </div>
  
  </div> -->


  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img src="assets/images/task/task.svg" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="section-title me-lg-5">
          <h1 class="text-primary"><i class="uil uil-list-ui-alt"></i></h1>
          <!-- <h4 class="title mb-4">Plans and Collaborate <br> your content</h4> -->
          <p class="text-muted" style="text-align: justify;">চর্যা কুরিয়ার ও ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারটি কুরিয়ার ও পণ্য সরবরাহ ব্যবসার সাথে
            যুক্ত প্রতিষ্ঠানগুলোর কথা বিবেচনায় নিয়ে তৈরি করা হয়েছে। এ সফটওয়্যারটি ব্যবহারে কুরিয়ার ও পণ্য সরবরাহ
            ব্যবস্থাপনা সহজ ও দ্রুততর হবে। ব্যবসায় আনবে স্বচ্ছতা। পাশাপাশি সফটওয়্যার ব্যবহারকারী প্রতিষ্ঠানের বাড়তি
            ব্যয়ও সংকোচন করবে।</p>
          <!-- <ul class="list-unstyled text-muted">
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Digital Marketing Solutions for Tomorrow
            </li>
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Create your own skin to match your brand
            </li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="mdi mdi-chevron-right"></i></a> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Discover what makes <span class="text-primary">Task manager</span> great.</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
     
    </div>
   

    <div class="row align-items-center">
      <ul ngbNav #nav="ngbNav" class="nav-pills flex-column rounded col-md-5 pr-0">
        <li ngbNavItem class="bg-light rounded-md">
          <a ngbNavLink class="rounded">
            <div class="p-3 text-left">
              <h5 class="title font-weight-bold">Management Dashboard</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by web
                designers.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/task/apps.png" class="img-fluid mx-auto rounded-md shadow-lg d-block" alt="">
          </ng-template>
        </li>
        <li ngbNavItem class="bg-light mt-4 rounded-md">
          <a ngbNavLink class="rounded">
            <div class="p-3 text-left">
              <h5 class="title font-weight-bold">Management Timeline</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by web
                designers.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/task/widgets2.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
        <li ngbNavItem class="bg-light mt-4 rounded-md">
          <a ngbNavLink class="rounded">
            <div class="p-3 text-left">
              <h5 class="title font-weight-bold">Payment Management</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by web
                designers.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/task/task.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
        <li ngbNavItem class="bg-light mt-4 rounded-md">
          <a ngbNavLink class="rounded">
            <div class="p-3 text-left">
              <h5 class="title font-weight-bold">File Integrate</h5>
              <p class="text-muted tab-para mb-0">Dummy text is text that is used in the publishing industry or by web
                designers.</p>
            </div>
          </a>
          <ng-template ngbNavContent>
            <img src="assets/images/task/file.png" class="img-fluid mx-auto d-block" alt="">
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="col-md-7 col-12 mt-4 pt-2"></div>
    </div>
   
  </div> -->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-12">
        <div class="sticky-bar">
          <div class="section-title text-lg-left mb-4 mb-lg-0 pb-2 pb-lg-0">
            <h4 class="title mb-4" >চর্যা কুরিয়ার ও ডেলিভারি ম্যানেজমেন্ট সফটওয়্যারটি’র বৈশিষ্ট্যসমূহ</h4>
            <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-12">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-meeting-board"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>নিজস্ব মার্চেন্ট প্যানেল</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">কুরিয়ার ওয়েবসাইট থেকে মার্চেন্টরা খুব সহজেই তাদের নিজস্ব একাউন্ট
                      খুলতে পারবেন। পরবর্তীতে এই একাউন্ট ব্যবহার করে মার্চেন্ট তাদের অর্ডার তৈরি করতে পারবেন। এমনকি
                      মার্চেন্টরা চাইলে এক্সেল সিট (Excel Sheet) থেকে একসাথে একাধিক অর্ডার আপলোড (Bulk Order Upload)
                      করতে পারবেন।</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-schedule"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>পার্সেল ট্র্যাকিং (Parcel Tracking)</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">বিক্রেতা (Merchant) ও ক্রেতার জন্য পার্সেলের অবস্থান জানবার সুবিধা
                      প্রদান প্রতিটি কুরিয়ার কর্তৃপক্ষের জন্য একটি প্রয়োজনীয় ও জরুরি সেবা হিসেবে বিবেচিত হয়ে থাকে। কাজেই
                      চর্যা কুরিয়ার ও ডেলিভারি সফটওয়্যারে সেবাটি সংযুক্ত করা হয়েছে। এ সফটওয়্যার ব্যবহারে মার্চেন্ট তার
                      ওর্ডার প্লেস করার পর মার্চেন্ট তার পার্সেলের অবস্থান সম্পর্কে তাৎক্ষণিকভাবে জানতে পারবেন। পাশাপাশি
                      অর্ডার প্লেস করার পরপরই গ্রাহকদের ফোনে একটি মেসেজ চলে যাবে যেখানে ওয়েবসাইটের ঠিকানা ও অর্ডার আইডি
                      থাকবে। এবং পরবর্তীতে ওয়েবসাইটে প্রবেশ করে গ্রাহকরা তাদের অর্ডার আইডি দিয়ে পার্সেলের বর্তমান অবস্থা
                      সম্পর্কে জানতে পারবেন।</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-clock"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>প্রতারক চিহ্নিতকরণ (Fraud Detection):</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">অনেক সময় দেখা যায় যে, কোন গ্রাহকরূপী প্রতারক পণ্য অর্ডার করার পর তা
                      গ্রহণ করতে চায় না। এতে করে মার্চেন্টের সাথে ডেলিভারি কোম্পানিগুলোর ভুল বোঝাবুঝির সৃষ্টি হয়।
                      ডেলিভারি কোম্পানিকে ভোগান্তিতে পড়তে হয়। এই সমস্যা এড়াতে এই সফটওয়্যারে থাকছে প্রতারক চিহ্নিতকরণ
                      (Fraud Detection) সুবিধা। কোন গ্রাহক যদি একাধিকবার অর্ডার দিয়ে অর্ডার গ্রহণ না কোরে প্রতারণার
                      আশ্রয় নেয় তবে অর্ডার প্লেস করার পর মার্চেন্ট তা দেখতে পাবেন।</p>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->

          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-calender"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>মার্চেন্ট পেমেন্ট স্ট্যাটাস অপশন</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;"> এডমিন প্যানেল থেকে কোন মার্চেন্ট কত টাকা পাবেন, কত টাকা তাকে
                      পরিশোধ করা হয়েছে ও কত টাকা বাকি আছে তা দেখতে পারবেন। এছাড়াও কুরিয়ার কর্তৃপক্ষ মার্চেন্টের
                      দেনা-পাওনার রিপোর্ট ডাউনলোড করে নিতে পারবেন। একইভাবে মার্চেন্ট তার নিজস্ব প্যানেলে তার অর্থের
                      হিসাব দেখতে পাবেন।</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-presentation-plus"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>CoD (Cash on Delivery) চার্জ, এলাকা (Area) ও ওজন (Weight) অনুযায়ী চার্জ নির্ধারণ:</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">এই অপশনটি পণ্য পরিবহনে তিনটি বিবেচনায় (CoD চার্জ, এলাকা ও ওজন
                      অনুযায়ী) উপর ভিত্তি করে বিভিন্ন মূল্য নির্ধারণে বাড়তি সুবিধা দিয়ে থাকে। এতে করে প্রতি অর্ডারের
                      জন্য আলাদাভাবে এন্ট্রি করতে হয় না এবং সময়ের সাশ্রয় হয়।</p>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil  uil-qrcode-scan"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>স্টিকার বারকোড প্রিন্ট ও স্ক্যানিং</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">রাইডারদের পণ্য ডেলিভারির সুবিধার্থে প্রতিটি পার্সেলের বারকোড সহ
                      ডেলিভারি লিস্ট প্রিন্ট করা যাবে। কর্মদিবসের শেষে রাইডার তার ডেলিভারি লিস্টটি জমা দিলে, অফিস
                      কর্তৃপক্ষ ডেলিভারি লিস্টের বারকোড স্ক্যান করে একাধিক অর্ডারের স্ট্যাটাস দ্রুততম সময়ের ভিতর আপডেট
                      করতে পারবেন।</p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-truck-loading"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>আসল গ্রাহকের কাছে পণ্য সরবরাহ নিশ্চিতকরণ (OTP Verification)</h5>
                    <p class="para text-muted mb-0" style="text-align: justify;">সফটওয়্যারটিতে আসল গ্রাহকের কাছে পণ্য সরবরাহ করা হয়েছে কিনা তা
                      নিশ্চিত করার জন্য One Time Verification (OTP) ব্যবস্থা সংযুক্ত করা হয়েছে। তবে এই সুবিধা পেতে হলে
                      রাইডারের কাছে অবশ্যই স্মার্ট ফোন থাকতে হবে।</p>
                  </div>
                </div>
              </div>

              <!-- <div class="col-12 mt-4 pt-2 text-md-left">
                <a href="javascript:void(0)" class="btn btn-primary">See more
                  <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
              </div> -->
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">আসুন এখানে কিছু আসল দাম করা যাক।</h4>
          <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary font-weight-bold">Charja</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-end">
      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil uil-lightbulb-alt"></i></h1>
            <h5 class="price-title">প্রিমিয়াম</h5>
            <p class="mb-0 text-muted">প্রিমিয়ামের জন্য উপযুক্ত</p>
          </div>
          <div class="border-bottom py-4">
            <h6 class="text-muted mb-0 fw-normal">মাসিক চার্জ</h6>
            <h2 class="fw-bold">৩,০০০</h2>

            <a href="javascript:void(0)" class="btn btn-primary mt-4">প্রিমিয়াম শুরু করুন</a>
          </div>
          <div class="pricing-features text-start p-4">
            <h5>মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>৫০০
              </li>
              <!-- <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Enhanced Security
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Source Files
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>1 Domain Free
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Appointments
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Installment
              </li> -->
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow-lg">
          <div class="price-header border-bottom bg-primary pt-5 pb-5">
            <h1 class="text-white-50"><i class="uil uil-award"></i></h1>
            <h5 class="price-title text-white">ব্রোঞ্জ</h5>
            <p class="mb-0 text-light"> ব্রোঞ্জের জন্য উপযুক্ত</p>
          </div>
          <div class="border-bottom py-5">
            <h6 class="text-muted mb-0 fw-normal">মাসিক চার্জ</h6>
            <h2 class="fw-bold">৫,০০০</h2>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">ব্রোঞ্জ শুরু করুন</a>
          </div>
          <div class="pricing-features text-start p-4">
            <h5>মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>১৫০০
              </li>
              <!-- <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>Enhanced Security
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>Source Files
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>1 Domain Free
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>Free Appointments
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-primary me-2"></i-feather>Free Installment
              </li> -->
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil uil-briefcase-alt"></i></h1>
            <h5 class="price-title">সিলভার</h5>
            <p class="mb-0 text-muted">
              সিলভারের জন্য উপযুক্ত</p>
          </div>
          <div class="border-bottom py-4">

            <h6 class="text-muted mb-0 fw-normal">মাসিক চার্জ</h6>
            <h2 class="fw-bold">৯,০০০</h2>

            <a href="javascript:void(0)" class="btn btn-primary mt-4">সিলভার শুরু করুন</a>
          </div>
          <div class="pricing-features text-start p-4">
            <h5>মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>৩০০০
              </li>
              <!-- <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Enhanced Security
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Source Files
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>1 Domain Free
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Appointments
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Installment
              </li> -->
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <!--end col-->

      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil  uil-gold"></i></h1>
            <h5 class="price-title">গোল্ড</h5>
            <p class="mb-0 text-muted">
              গোল্ডের জন্য উপযুক্ত</p>
          </div>
          <div class="border-bottom py-4">
            <h6 class="text-muted mb-0 fw-normal">মাসিক চার্জ</h6>
            <h2 class="fw-bold">১৫,০০০</h2>

            <a href="javascript:void(0)" class="btn btn-primary mt-4">গোল্ড শুরু করুন</a>
          </div>
          <div class="pricing-features text-start p-4">
            <h5>মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>৮০০০
              </li>
              <!-- <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Enhanced Security
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Source Files
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>1 Domain Free
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Appointments
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Installment
              </li> -->
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
      <div class="col-lg-2-5 col-md-6 col-12 mt-4 pt-2">
        <div class="pricing text-center rounded overflow-hidden shadow">
          <div class="price-header border-bottom pt-5 pb-5">
            <h1 class="text-primary"><i class="uil uil-brightness-half"></i></h1>
            <h5 class="price-title">প্লাটিনাম</h5>
            <p class="mb-0 text-muted">
              প্লাটিনামের জন্য উপযুক্ত</p>
          </div>
          <div class="border-bottom py-4">
            <h6 class="text-muted mb-0 fw-normal">মাসিক চার্জ</h6>
            <h2 class="fw-bold">২২,০০০</h2>

            <a href="javascript:void(0)" class="btn btn-primary mt-4">প্লাটিনাম শুরু করুন</a>
          </div>
          <div class="pricing-features text-start p-4">
            <h5>মাসিক অর্ডার</h5>
            <ul class="feature list-unstyled mb-0">
              <li class="text-muted">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>২০০০০
              </li>
              <!-- <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Enhanced Security
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Source Files
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>1 Domain Free
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Appointments
              </li>
              <li class="text-muted mt-2">
                <i-feather name="arrow-right" class="fea icon-sm text-dark me-2"></i-feather>Free Installment
              </li> -->
            </ul>
          </div>
        </div>
        <!--end price three-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <!-- <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
            <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is
              of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
          </div>
        </div>
      </div> -->
     

      <!-- <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> What is the main process open account ?</h5>
            <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making a neutral judgement on the visual impact</p>
          </div>
        </div>
      </div> -->
    
<!-- 
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> How to make unlimited data entry ?</h5>
            <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively
              realistic so that the layout impression of the final publication is not compromised.</p>
          </div>
        </div>
      </div> -->
     

      <!-- <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?</h5>
            <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin.</p>
          </div>
        </div>
      </div> -->
    
    </div>
  

    <div class="row mt-5 pt-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span
              class="text-primary font-weight-bold">Charja</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
          <a routerLink="/page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Features End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->