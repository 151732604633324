import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {

    constructor(public toastrService: ToastrService) {

    }

    success(message: string, header: string): void {
        this.toastrService.success(message, header, {
            closeButton: true,
            timeOut: 1000,
            progressBar: true,
            progressAnimation: 'increasing',
            disableTimeOut: false,
            positionClass: 'toast-top-center',
        });
    }

    successLogin(message: string, header: string, time: any): void {
        this.toastrService.success(message, header, {
            closeButton: true,
            timeOut: time,
            progressBar: true,
            progressAnimation: 'increasing',
            disableTimeOut: false,
            positionClass: 'toast-bottom-center',
        });
    }

    error(message: string, header: string): void {
        this.toastrService.error(message, header, {
            closeButton: true,
            disableTimeOut: false,
            positionClass: 'toast-top-center',
        });
    }

    warning(message: string, header: string): void {
        this.toastrService.warning(message, header, {
            closeButton: true,
            disableTimeOut: false,
            timeOut: 4000,
            positionClass: 'toast-top-center',
        });
    }

    info(message: string, header: string): void {
        this.toastrService.info(message, header, {
            closeButton: true,
            disableTimeOut: false,
        });
    }

    show(message: string, header: string): void {
        this.toastrService.show(message, header, {
            closeButton: true,
            disableTimeOut: false,
        });
    }
    finalerror(message: string, header: string): void {
        this.toastrService.error(message, header, {
            closeButton: true,
            disableTimeOut: false,
            timeOut: 3000,
            positionClass: 'toast-top-center',
        });
    }

    newOrder(message: string, header: string): void {
        this.toastrService.success(message, header,
            {
                closeButton: true,
                timeOut: 15000,
                progressBar: true,
                progressAnimation: 'increasing',
                disableTimeOut: false,
                positionClass: 'toast-top-center',
            });
    }
}
