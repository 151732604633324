import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailService } from 'src/app/email/email.service';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { ToasterService } from 'src/app/shared/http-services/toast.service';
import { Buffer } from "buffer";

interface form {
  profile: string,
  name: string,
  date: string,
  time: string,
  content: string,
};

@Component({
  selector: 'app-page-trial',
  templateUrl: './page-trial.component.html',
  styleUrls: ['./page-trial.component.css']
})

/**
 * Page Trial Component
 */
export class PageTrialComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false;
  form: FormGroup

  formData: form[] = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Calvin Carlo",
      date: "16th August, 2019",
      time: "03:44 pm",
      content: "Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with 'real' content. This is required when, for example, the final text is not yet available."
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Crista Joseph",
      date: "16th August, 2019",
      time: "03:44 pm",
      content: "Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with 'real' content.for example, the final text is not yet available."
    },
    // {
    //   profile: "assets/images/client/06.jpg",
    //   name: "George Meta",
    //   date: "16th August, 2019",
    //   time: "03:44 pm",
    //   content: "Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with 'real' content. This is required when, for example, the final text is not yet available."
    // }
  ];

  countries: any = [{ id: 1, name: 'Bangladesh' }, { id: 2, name: 'Pakistan' }, { id: 3, name: 'Afghanistan' }];
  prymayInterests: any = [{ id: 1, name: 'Use it in my company' }, { id: 2, name: 'Offer Charja ERP to Other Company' }, { id: 3, name: 'I am a student' }, { id: 4, name: 'I am a techer' }];

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private router: Router, private spinner: NgxSpinnerService,
    private loginService: LoginService, private toastService: ToasterService, private mail: EmailService) { }

  ngOnInit(): void {
    this.formInitialization();
  }

  formInitialization(): void {
    this.form = this.formBuilder.group({
      concernName: ['', Validators.required],
      customerDomain: ['', Validators.required],
      countrySerial: ['', Validators.required],
      concernSize: ['', Validators.required],
      customerPurpose: ['', Validators.required],
      appGroup: [0]
      // confirmPassword : ['', Validators.required]
    });
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  functionname(id: any): void {

  }

  showSpinner(): void {
    this.toastService.warning('Oops !!.. something went wrong please contact with administrator', '');
    // this.spinner.show();

    // setTimeout(() => {

    //   this.spinner.hide();
    // }, 5000);
  }



  creat_trial(): void {
    this.spinner.show();
    let arr = [9, 10, 11];
    let ids = this.loginService.solutions.map((x: { solutionSerial: any }) => x.solutionSerial);
    // let ids = this.loginService.solutions.filter((d: { isChecked: boolean }) => d.isChecked == true).map((y: { id: any }) => y.id);

    // const contains = arr.filter((element: number) => {
    //   return ids.includes(element);
    // });
    // let [eCom, courier, copp] = contains;
    // if (eCom != undefined) {

    // }

    let model = {
      seal: parseInt(localStorage.getItem('chief')),
      modules: ids.toString(),
      updateRegi: this.form.value
    }

    this.loginService.updateRegister(model).subscribe(res => {
      if (res.result.updateRegi.dbname != null) {

        let obj = {
          serverName: '45.115.115.155',
          databaseName: res.result.updateRegi.dbname,
          coreEssentialSerial: res.result.updateRegi.coreEssentialSerial
        };
        this.exeDMLSesame(obj);

      } else {
        this.spinner.hide();
        this.toastService.warning('Oops !!.. something went wrong please contact with administrator', '');
      }


    }, error => {
      this.spinner.hide();
    });

  }

  exeDMLSesame(obj: any): void {
    this.loginService.exeDMLSesame(obj).subscribe(res => {
      if (res.result != null) {
        let obj = {
          serverName: '45.115.115.155',
          databaseName: res.result.databaseName,
          seal: parseInt(localStorage.getItem('chief'))
        };
        this.exeDMLBasicExtra(obj);
      } else {
        this.spinner.hide();
        this.toastService.warning('Oops !!.. something went wrong please contact with administrator', '');
      }

    }, error => {
      this.spinner.hide();
    });

  }

  exeDMLBasicExtra(obj: any): void {
    this.loginService.exeDMLBasicExtra(obj).subscribe(res => {
      if (res.result != null) {
        this.router.navigate(['/login']);
      //  this.send_test_mail(res.result.customerName, res.result.customerEmail, res.result.customerPhone);
      } else {
        this.spinner.hide();
        this.toastService.warning('Oops !!.. something went wrong please contact with administrator', '');
      }
    }, error => {
      this.spinner.hide();
    })
  }

  send_test_mail(client: string, email: string, mobile: string): void {

    let emailf = Buffer.from(email).toString('base64');
    let mobilef = Buffer.from(mobile).toString('base64');

    let mailContent = this.mail.emailFormate(client, emailf, mobilef);
    let obj = {
      receiverEmail: email,
      subject: 'Account Varification',
      mailContent: mailContent
    };

    this.loginService.sendMail(obj).subscribe(res => {
      if (res.result) {
        this.spinner.hide();
        this.router.navigate(['/page-actions']);
      } else {
        this.toastService.warning("Can't send email..Please contact with administrator", "");
        this.spinner.hide();
      }
    });
  }
}
