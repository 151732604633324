<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 "
  style="background-image: url('assets/images/charja/solutions-top-background-line-art.png');" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h1 class="title mb-0" style="color: #fff;text-transform: uppercase;">Point of Sale (POS)  </h1>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <!-- <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
          </nav>
      </div> -->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center"
      style="background-image: url('assets/images/charja/clientele-body-background-line-art.png');background-size: 100% 100%;background-repeat: no-repeat;">
      <div class="section-title ms-lg-4">
        <!-- <h4 class="title mb-4 ja-style">Who Can Apply ?</h4> -->
        <p class="text-muted" style="text-align: justify;">Shop business is the most fast-growing business in any
          country as well as in Bangladesh.
          Bangladesh economy is also rising very fast. Bangladeshi people’s costumes are also changing. Now people
          likes to go to shopping mall, decorated and sophisticated shops; likes to purchase by credit card. Shops are
          also moving to be automated.

          This POS software covers purchase and store control of all items as you desire. Multiple sales points in one
          sales center is available here. You can maintain multiple warehouses. You can keep record of your staff,
          supplier and customer info. You can manage and control all of your various offers and discounts. Customer
          will get a smooth, sophisticated and fantastic service if seller use this POS software. Good service means
          better return..</p>
      </div>
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <ul class="list-unstyled text-muted">
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Multiple Shop and Counter Creation.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Counterwise Machine Allocation.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Multiple Warehouse Creation.
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Specify Business Counterparts’ Category.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Add Customer and Suppliers’ Basic Informations.
          </li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Record Personnels’ Basics.
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Historical Data input for Product Price, VAT
          </li>
          <li class="mb-0" style="margin-left:15px"><span class="text-primary h5 me-2">
            </span>
            (Inclusive and Exclusive Method) & TAX.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Manage Offer and Discount.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Designate Personnels’ Position and Location.
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Add Product Category and Product.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Generate Barcode and Label.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Record Measuring Units.
          </li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Multiple Currency Transaction.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Store Transaction (inward and outward
            Transaction).</li>

          <!-- ............ -->


          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>In-house Transaction(Shop to Shop or Warehouse to</li>
          <li class="mb-0" style="margin-left:16px"><span class="text-primary h5 me-2">
            </span>
            Shop)</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Sales Entry using Barcode Scanner.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Multile Payment Method (Debit or Credit

          </li>
          <li class="mb-0" style="margin-left:16px"><span class="text-primary h5 me-2">
            </span>
            Card/Cheque/bKash).</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Integrated with Inventory.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Refund and Return Issues.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Income & Expenditure Entry Option.
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Daily and Periodic Sales and Purchase Reports
            </li>
          <li class="mb-0" style="margin-left:15px"><span class="text-primary h5 me-2">
            </span>
            (Shopwise and Concernwise).</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Stock in and out Reports.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Stock Value Report.
          </li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>TAX and VAT Reports.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Reports for Product Offer and Discount.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Profit & Loss Statement.
          </li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Graph Report Presentation of Periodic Sales &
            </li>
            <li class="mb-0" style="margin-left:15px"><span class="text-primary h5 me-2">
            </span>
            Purchase.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Cloud and On-site Version.</li>

          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Unlimited User.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>Access from Anywhere.</li>
          <li class="mb-0"><span class="text-primary h5 me-2">
              <img width="18px" src="assets/images/charja/solutions-tick.png">
            </span>24X7 Online Client Support.</li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <div class="position-relative">
            <img src="assets/images/charja/pos.png" class="rounded img-fluid mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <div style="text-align: center;">
        <a routerLink="/signup" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Key Features</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>

    <app-features></app-features>
  </div> -->

</section>


<!-- Team Start -->
<!-- class="bg-light" -->
<!-- <section class="section ">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 ja-style">Our Greatest Minds</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Charja</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>

    </div>


    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
              <ul class="list-unstyled mb-0 team-icon">
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-primary btn-pills btn-sm btn-icon ms-1" *ngFor="let item of data.list">
                    <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
              <small class="designation text-muted">{{data.designation}}</small>
            </div>
          </div>
        </div>
      </div>
     
    </div>


    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title mb-4">See everything about your employee at one place.</h4>
            <p class="text-muted para-desc mx-auto">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>

            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Get Started Now</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a>
            </div>
          </div>
        </div>
      
      </div>
  
    </div>
  </div>
</section> -->

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>