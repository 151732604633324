import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/shared/http-services/login.service';
import { Buffer } from "buffer";

interface form {
  title: string,
  content: string,
  post: string,
  topic: number,
  comments: number,
};

@Component({
  selector: 'app-page-welcome',
  templateUrl: './page-welcome.component.html',
  styleUrls: ['./page-welcome.component.css']
})

/**
 * Forums Component
 */
export class PageWelcomeComponent implements OnInit {

  // navClass = 'nav-light';
  Menuoption = 'center';
  Settingicon = false;
  dataSource: any = [];
  /**
   * Forms Data
   */
  // formData: form[] = [
  //   {
  //     title: "Introductions: Landrick",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Calvin",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Web Designing and Developing",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "George",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Hosting and providers",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Parthiv",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "SEO starter guide",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "David",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Troubleshooting and managing issues",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Tiger",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Backup and restore",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Cristina",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Errors and how to fix them",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Miriam",
  //     topic: 3,
  //     comments: 5
  //   },
  //   {
  //     title: "Template features & Services",
  //     content: "Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.",
  //     post: "Janalia",
  //     topic: 3,
  //     comments: 5
  //   }
  // ];

  constructor(private loginService: LoginService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.inIt();
  }

  inIt(): void {
    this.spinner.show();
    let cheif = parseInt(localStorage.getItem('chief'));
    // this.loginService.demoDetails(cheif).subscribe({
    //   next: x => this.dataSource = x.result,
    //   error: (e) => console.error(e),
    // });
    this.loginService.demoDetails(cheif).subscribe({
      next: x => { 
        this.dataSource = x.result;
        this.spinner.hide();
       },
      error: (e) =>{
        console.error(e);
        this.spinner.hide();
      },
    });
    
  }

  goto_demo(data: any): void {

    let p = localStorage.getItem('seal');
    let n = Buffer.from(data.customerEmail).toString('base64');
    let t = Buffer.from(data.dbname).toString('base64');;
    let st = `http://localhost:8300/#/session/loginV2?cid="+ ${n} +"&pwd="+ ${p}+"&t="+ ${t}`;
    window.open(st, "_self");
  }

  getSolutionName(data: any) {

    //     let solution = `<ul class="list-unstyled text-muted dropdown-content" >
    //    <li class="mb-0" *ngFor="let solution of ${data} "><span class="text-primary h5 me-2"><i
    //          class="uil uil-check-circle align-middle"></i></span>{{solution}}</li>
    //  </ul>`

    // let solution = `<table class="table table-striped">
    // <thead>

    // <tr>
    // <th></th>
    // </tr>
    // </thead>
    // <tbody>
    //               <tr *ngFor="let data of ${data}">
    //               <td>{{data}}</td>
    //               </tr>
    //               </tbody>
    // </table>`

    // let solution = `<span class="text-primary h5 me-2"><i
    //         class="uil uil-check-circle align-middle"></i> ${data.toString()}</span>`
    // return data.toString().split(',').join("<br />");
    //return `<span> ${data.toString().split(',').join('\n')}</span>` ;

    var d = data.map((s: any) => s).join(" , ");

    return d;
  }
}
