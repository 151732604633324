<div class="row">
    <div class="col-md-3 col-12" *ngFor="let data of servicesData">
        <div class="{{service_class}}">
            <div class="image position-relative d-inline-block">
                <!-- <i class="{{data.icon}}"></i> -->
                <i img="{{data.icon}}"></i>
                <img src="{{data.icon}}" class="img-fluid" alt="">
            </div>

            <div class="content mt-3">
                <h5 style="font-size: 1.2rem !important;font-weight: bold;color: #2f55d4;">{{data.title}}</h5>
                <p class="text-muted mb-0" style="text-align: justify !important;">{{data.description}}</p>
            </div>
        </div>
    </div>
    <!--end col-->
</div>